export interface FilterObject {
  text: string | number;
  value: string | number;
}

export interface FilterInterface {
  investments: {
    byAsset: FilterObject | null;
    byInvestor: FilterObject | null;
    byPublished: boolean;
  };
}

/**
 * Returns the initial filter object
 */
export const getInitialFilters = (): FilterInterface => ({
  investments: {
    byAsset: null,
    byInvestor: null,
    byPublished: true,
  },
});

export class Filters implements FilterInterface {
  investments = getInitialFilters().investments;
}
