import { GreenTea } from '@/types/greenTea';

export const clientName = (): string | undefined =>
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_BRAND
    : process.env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID?.split('-bloqify')[0];

export const clientConfig = (): GreenTea => {
  const clientNameLocal = clientName()?.toLowerCase();
  return clientNameLocal && require(`../../client-configs/${clientNameLocal}.config`);
};
