import { Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyStorage, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { Vertebra, generateState, mutateState } from '@/store/utils/skeleton';
import { generateFileMd5Hask } from '@/store/utils/files';
import { Project } from '@/models/assets/Project';
import { validateMimeTypes } from '../utils/validateMimeTypes';
import { StorageError } from 'firebase/storage';

const SET_PROJECT = 'SET_PROJECT';

export interface AddProjectParameters {
  project: Project;
  assetId: string;
}
export interface UpdateProjectParameters {
  project: Project;
  assetId: string;
  projectId: string;
  updatedImages: boolean;
  updatedProjectDoc: boolean;
}
export interface DeleteProjectParameters {
  assetId: string;
  projectId: string;
}

export default {
  state: generateState(),
  mutations: {
    [SET_PROJECT](
      state,
      { status, payload, operation }: { status: DataContainerStatus; payload?: unknown; operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addProject({ commit }, { project, assetId }: AddProjectParameters): Promise<void> {
      commit(SET_PROJECT, { status: DataContainerStatus.Processing, operation: 'addProject' });

      const storageRef = bloqifyStorage.ref();
      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const newProjectRef = assetRef.collection('projects').doc();
      const files: Record<string, File[]> = {};
      const storageChildren: { file: File; ref: firebase.storage.Reference }[] = [];
      const filesKeyNames = ['images', 'projectDoc'];

      // Building propper objects: asset (to send to the database) and files (to send to storage)
      // Setting up an array with all the files to be uploaded
      filesKeyNames.forEach((keyName): void => {
        project[keyName] = project[keyName].map((file: File): string => {
          const fullPath = `assets/${assetRef.id}/project/${newProjectRef.id}/${file.name}`;

          storageChildren.push({
            file,
            ref: storageRef.child(fullPath),
          });

          // Creating / pushing files object
          if (files[keyName]) {
            files[keyName].push(file);
          } else {
            files[keyName] = [file];
          }

          // The asset object only needs the filename as a reference for the database
          return fullPath;
        });
      });

      // Now, after the files have been processed, check the MIME types
      const [mimeError, mimeSuccess] = await to(
        validateMimeTypes(
          storageChildren.map(({ file }): File => file),
          'ALL',
        ),
      );
      if (mimeError || mimeSuccess) {
        return commit(SET_PROJECT, {
          status: DataContainerStatus.Error,
          payload: Error('Error MIME file.'),
          operation: 'addProject',
        });
      }

      // The comparison of the md5Hash could have been done here via JavaScript (customMetadata.md5Hash) but it's also possible via
      // Firestore rules. The only caveat is that the error handling is not good at all, we cannot identify
      // what kind of error we are getting from the rules, only no permission.
      let storageResultsAndErrors:
        | firebase.storage.UploadTaskSnapshot
        | firebase.functions.HttpsError
        | firebase.storage.FirebaseStorageError[];
      try {
        storageResultsAndErrors = await Promise.all(
          storageChildren.map(async (child): Promise<firebase.storage.FirebaseStorageError> => {
            const md5Hash = await generateFileMd5Hask(child.file, true);

            // Return all errors if there are any
            return child.ref.put(child.file, { customMetadata: { md5Hash } }).catch((err): Error => err);
          }),
        );
      } catch (e) {
        // Set error if there is any other kind of error than a FirebaseStorageError
        return commit(SET_PROJECT, { status: DataContainerStatus.Error, payload: e, operation: 'updateAsset' });
      }
      // Check if there is any other FirebaseStorageError error than 'storage/unauthorized',
      // since it's the only one we have to check if the md5 exists (check rules)
      const differentError = storageResultsAndErrors.some(
        // @ts-expect-error - ToDo: fix types
        (resultOrError): boolean => resultOrError.code && resultOrError.code !== 'storage/unauthorized',
      );
      if (differentError) {
        return commit(SET_PROJECT, {
          status: DataContainerStatus.Error,
          payload: Error('Error uploading files.'),
          operation: 'updateAsset',
        });
      }

      // previous but with firestore transaction
      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const [getAssetError, getAsset] = await to(assetRef.get());
          if (getAssetError) {
            throw getAssetError;
          }

          const clientName = getAsset?.get('clientName') as string | undefined;

          transaction.update(assetRef, {
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });
          const newProject: Project = {
            ...project,
            ...(clientName && { clientName }),
            createdDateTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
            deleted: false,
          };
          transaction.set(newProjectRef, newProject);
        }),
      );
      if (transactionError) {
        return commit(SET_PROJECT, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'addProject',
        });
      }

      return commit(SET_PROJECT, {
        status: DataContainerStatus.Success,
        payload: { transactionSuccess, id: newProjectRef.id },
        operation: 'addProject',
      });
    },
    async updateProject(
      { commit },
      { assetId, project, projectId, updatedImages, updatedProjectDoc }: UpdateProjectParameters,
    ): Promise<void> {
      commit(SET_PROJECT, { status: DataContainerStatus.Processing, operation: 'updateProject' });

      const storageRef = bloqifyStorage.ref();
      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const projectRef = assetRef.collection('projects').doc(projectId);

      if (updatedImages || updatedProjectDoc) {
        const filesKeyNames = [...(updatedImages ? ['images'] : []), ...(updatedProjectDoc ? ['projectDoc'] : [])];
        const files: Record<string, File[]> = {};
        const storageChildren: { file: File; ref: firebase.storage.Reference }[] = [];

        // Building propper objects: asset (to send to the database) and files (to send to storage)
        // Setting up an array with all the files to be uploaded
        filesKeyNames.forEach((keyName): void => {
          project[keyName] = project[keyName].map((file: File): string => {
            const fullPath = `assets/${assetRef.id}/${file.name}`;

            storageChildren.push({
              file,
              ref: storageRef.child(fullPath),
            });

            // Creating / pushing files object
            if (files[keyName]) {
              files[keyName].push(file);
            } else {
              files[keyName] = [file];
            }

            // The asset object only needs the filename as a reference for the database
            return fullPath;
          });
        });

        // Now, after the files have been processed, check the MIME types
        // const [mimeError, mimeSuccess] = await to(
        //   validateMimeTypes(
        //     storageChildren.map(({ file }): File => file),
        //     'ALL',
        //   ),
        // );
        // if (mimeError || mimeSuccess) {
        //   return commit(SET_PROJECT, {
        //     status: DataContainerStatus.Error,
        //     payload: Error('Error MIME file.'),
        //     operation: 'updateProject',
        //   });
        // }
        // The comparison of the md5Hash could have been done here via JavaScript (customMetadata.md5Hash) but it's also possible via
        // Firestore rules. The only caveat is that the error handling is not good at all, we cannot identify
        // what kind of error we are getting from the rules, only no permission.
        let storageResultsAndErrors: (
          | firebase.storage.UploadTaskSnapshot
          | firebase.functions.HttpsError
          | firebase.storage.FirebaseStorageError
        )[];
        try {
          storageResultsAndErrors = await Promise.all(
            storageChildren.map(async (child): Promise<firebase.storage.UploadTask> => {
              const md5Hash = await generateFileMd5Hask(child.file, true);

              // Return all errors if there are any
              return child.ref.put(child.file, { customMetadata: { md5Hash } }).catch((err): Error => err);
            }),
          );
        } catch (e) {
          // Set error if there is any other kind of error than a FirebaseStorageError
          return commit(SET_PROJECT, { status: DataContainerStatus.Error, payload: e, operation: 'updateAsset' });
        }
        // Check if there is any other FirebaseStorageError error than 'storage/unauthorized',
        // since it's the only one we have to check if the md5 exists (check rules)
        const differentError = storageResultsAndErrors.some(
          (resultOrError): boolean =>
            // @ts-expect-error - ToDo: fix types
            (resultOrError as StorageError).code && resultOrError.code !== 'storage/unauthorized',
        );
        if (differentError) {
          return commit(SET_PROJECT, {
            status: DataContainerStatus.Error,
            payload: Error('Error uploading files.'),
            operation: 'updateAsset',
          });
        }
      }

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const [getProjectError, getProject] = await to(projectRef.get());

          if (getProjectError) {
            commit(SET_PROJECT, {
              status: DataContainerStatus.Error,
              payload: getProjectError,
              operation: 'updateProject',
            });
            return;
          }

          const prevProject = getProject?.data();

          transaction.update(assetRef, {
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });
          transaction.update(projectRef, {
            ...project,
            createdDateTime: prevProject?.createdDateTime,
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }),
      );

      if (transactionError) {
        return commit(SET_PROJECT, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'updateProject',
        });
      }

      return commit(SET_PROJECT, {
        status: DataContainerStatus.Success,
        payload: transactionSuccess,
        operation: 'updateProject',
      });
    },
    async deleteProject({ commit }, deleteProjectParameters: DeleteProjectParameters): Promise<void> {
      commit(SET_PROJECT, { status: DataContainerStatus.Processing, operation: 'deleteProject' });

      const assetRef = bloqifyFirestore.collection('assets').doc(deleteProjectParameters.assetId);
      const projectRef = assetRef.collection('projects').doc(deleteProjectParameters.projectId);

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const [getProjectError] = await to(projectRef.get());

          if (getProjectError) {
            commit(SET_PROJECT, {
              status: DataContainerStatus.Error,
              payload: getProjectError,
              operation: 'deleteProject',
            });
            return;
          }

          transaction.update(assetRef, {
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });
          transaction.update(projectRef, {
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
            deleted: true,
          });
        }),
      );

      if (transactionError) {
        return commit(SET_PROJECT, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'deleteProject',
        });
      }

      return commit(SET_PROJECT, {
        status: DataContainerStatus.Success,
        payload: transactionSuccess,
        operation: 'deleteProject',
      });
    },
  },
} as Module<Vertebra, State>;
