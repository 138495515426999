import firebase from 'firebase/compat/app';
import { OppStatus } from '../opp/Opp';

export interface Document {
  header: string;
  paths: (string | { file: File; name: string })[];
}

export interface Asset {
  id?: string;
  clientName?: string;
  investmentStepSize?: number;
  fundType?: string;
  brochure?: string[];
  city: string;
  country: string;
  email?: string;
  telephone?: string;
  companyNumber?: string;
  effectType?: string;
  companyType?: string;
  createdDateTime: firebase.firestore.Timestamp;
  deleted: boolean;
  /**
   * Firestore limitation of nested arrays forces to create intermediate object
   * when `dividendsFormat.contents[0][0] === 'fixed'`, it's fixed net dividends, so contents `length === 1`.
   */
  dividendsFormat: { contents: [string, number] }[];
  earningsPaymentSchedule?: string;
  emissionCost: number;
  euroMin: number;
  euroMax?: number;
  fixedDividends?: boolean;
  floorPlanImages?: string[];
  houseNumber: string;
  images?: string[];
  investmentCase?: string;
  name: string;
  postalCode: string;
  premium: boolean;
  risks?: string;
  ventureDetails?: Record<string, string>;
  prospectus?: string[];
  published: boolean;
  returnsAfterEnd?: number;
  sharePrice: number;
  sharesAvailable: number;
  sharesEnding?: number;
  sharesReserved?: number;
  startDateTime: firebase.firestore.Timestamp;
  endDateTime: firebase.firestore.Timestamp;
  street: string;
  totalValueEuro: number;
  totalValueShares: number;
  shortDescription?: Record<string, string>;
  checkoutDescription?: Record<string, string>;
  updatedDateTime?: firebase.firestore.Timestamp;
  reservation?: boolean;
  totalEuroEarnings?: number;
  totalEuroRepayments?: number;
  totalSharesRepayments?: number;
  totalEuroCosts?: number;
  showUnits?: boolean;
  hideDividendsFormat?: boolean;
  progressBarToggle?: boolean;
  enableFractionalInvesting?: boolean;
  walletId?: string; // OPP merchant uid
  oppData?: OppStatus; // Opp merchant status
  balance?: number; // OPP merchant balance
  documents?: Document[];
  stak?: boolean;
  stakCompanyName?: string;
  vimeoId?: string;
  tradingSharePrice?: number;
  tradingDocs?: string[];
  tradingEnabled?: boolean;
  isHidden?: boolean;
}

// Asset Converted created in order to just load from firestore the required fields
export class AssetConverter {
  constructor(
    readonly createdDateTime: firebase.firestore.Timestamp,
    readonly name: string,
    readonly endDateTime: firebase.firestore.Timestamp,
    readonly startDateTime: firebase.firestore.Timestamp,
    readonly fundType: string,
    readonly dividendsFormat: unknown[],
    readonly fixedDividends: boolean,
    readonly returnsAfterEnd: number,
    readonly premium: boolean,
    readonly enableFractionalInvesting: boolean,
    readonly sharePrice: number,
  ) {
    this.createdDateTime = createdDateTime;
    this.name = name;
    this.endDateTime = endDateTime;
    this.startDateTime = startDateTime;
    this.fundType = fundType;
    this.dividendsFormat = dividendsFormat;
    this.fixedDividends = fixedDividends;
    this.returnsAfterEnd = returnsAfterEnd;
    this.premium = premium;
    this.enableFractionalInvesting = enableFractionalInvesting;
    this.sharePrice = sharePrice;
  }
}

export const assetConverter = {
  toFirestore(asset): firebase.firestore.DocumentData {
    return {
      createdDateTime: asset.createdDateTime,
      name: asset.name,
      endDateTime: asset.endDateTime,
      startDateTime: asset.tartDateTime,
      fundType: asset.fundType,
      dividendsFormat: asset.dividendsFormat,
      fixedDividends: asset.fixedDividends,
      returnsAfterEnd: asset.returnsAfterEnd,
      premium: asset.premium,
      enableFractionalInvesting: asset.enableFractionalInvesting,
      sharePrice: asset.sharePrice,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): AssetConverter {
    const data = snapshot.data(options);
    return new AssetConverter(
      data.createdDateTime,
      data.name,
      data.endDateTime,
      data.tartDateTime,
      data.fundType,
      data.dividendsFormat,
      data.fixedDividends,
      data.returnsAfterEnd,
      data.premium,
      data.enableFractionalInvesting,
      data.sharePrice,
    );
  },
};
/**
 * Defining all the export type.
 */
export const enum ExportType {
  Excel = 'excel',
  Csv = 'csv',
}

export interface ExportRowType {
  transactionDate: string;
  paymentDate: string;
  amount: number | string;
  investorId: number;
  dividendType: string;
  description: string;
}

export interface ExportTableDataType {
  transactionDate: string;
  paymentDate: string;
  amount: number;
  investorId: number;
  investorName: string;
  dividendType?: string;
  description?: string;
  hasErrors: string[];
  errors: string[];
}
