<template>
  <div>
    <SidebarNavigation />
    <div :class="['main-content', outlineMode ? 'debug' : '']">
      <div class="container-fluid">
        <main class="col-12 col-lg-10 col-xl-10 mx-auto">
          <slot />
        </main>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { State as StateClass } from 'vuex-class';
import { State } from '@/models/State';
import SidebarNavigation from '@/components/navigation/Sidebar.vue';

@Component({
  components: {
    SidebarNavigation,
  },
})
export default class SidebarLayout extends Vue {
  @StateClass('outlineMode') outlineMode!: State['outlineMode'];
}
</script>
