import { firebase } from '@/boot/firebase';
import { Asset } from '@/models/assets/Asset';
import store from '@/store';
import { QueryFieldFilterConstraint, where } from 'firebase/firestore';

// type randomObject = Record<string, unknown>;

/**
 * Removes any document or collection reference in order to avoid arbitrary mutations (specially made for vuex)
 */
// export const removeReferencesFromDoc = async (
//   data: firebase.firestore.DocumentData,
//   documentReferencesToQuery?: string[],
//   leaveReferencesIds?: boolean,
// ): Promise<randomObject> =>
//   Object.entries(data).reduce(async (acc, [key, value]): Promise<randomObject> => {
//     // When the value is not a ref, we can just attach the property to the object
//     if (
//       !(value instanceof firebase.firestore.DocumentReference) &&
//       !(value instanceof firebase.firestore.CollectionReference)
//     ) {
//       (await acc)[key] = value;
//       // When the value is a *desired* ref, we need to query it and then attach the result to the object
//     } else if (
//       documentReferencesToQuery?.length &&
//       documentReferencesToQuery.includes(key) &&
//       value instanceof firebase.firestore.DocumentReference
//     ) {
//       const { to } = await import('await-to-js');
//       const [err, doc] = await to(value.get());
//       if (err) {
//         throw err;
//       }

//       if (doc.exists) {
//         const data = await removeReferencesFromDoc(doc.data());
//         (await acc)[key] = data;
//       }
//       // When the option leave references ids is set, we just attach the ref id to the object
//     } else if (leaveReferencesIds) {
//       (await acc)[key] = value.id;
//     }

//     return acc;
//   }, {});

/**
 * getId (par): string
 * Due to permissions errors we need to limit the vuexfire autoresolve feature. That creates an scenario in which object references
 * in a db object can be now an string, firestore reference or the object itself. This method retrieves the id in all the cases
 * @param par relationship field
 * @returns model id
 */
export function getId(par: string | firebase.firestore.DocumentReference | Asset): string {
  if (typeof par === 'string') {
    return par.split('/').pop()!;
  }
  return par?.id || '';
}

export const getManagerClientName = (): string | undefined => store.state.manager?.clientName;

/**
 * Adds a where clause to a query if the clientName is provided
 * @param {firebase.firestore.Query} query
 * @returns
 */
export const clientNameWrapper = (query: firebase.firestore.Query, clientName?: string): firebase.firestore.Query => {
  const internalClientName = getManagerClientName() || clientName;
  if (!internalClientName) {
    return query;
  }

  return query.where('clientName', '==', internalClientName);
};

/**
 * Get where clause for clientName
 * @returns {QueryFieldFilterConstraint | false}
 */
export const clientNameWrapperModular = (): QueryFieldFilterConstraint | void => {
  const internalClientName = getManagerClientName();
  if (!internalClientName) {
    return;
  }

  return where('clientName', '==', internalClientName);
};

/**
 * Get where clause for clientName
 * @returns {[string, string, string] | false}
 */
export const getClientNameFilter = (): [string, firebase.firestore.WhereFilterOp, string] | [] => {
  const internalClientName = getManagerClientName();
  if (!internalClientName) {
    return [];
  }

  return ['clientName', '==', internalClientName];
};
