/* eslint-disable no-useless-catch */
// https://github.com/Mikkelet/firebase-helpers/blob/master/batch_bulk.ts
// Modified by JFGHT
import { firebase } from '@/boot/firebase';

export default class BatchInstance {
  private _firestore: firebase.firestore.Firestore;
  private _batch: firebase.firestore.WriteBatch;
  private _size = 0;
  private _batches: firebase.firestore.WriteBatch[];

  /**
   * initiate batch
   */
  public constructor(fs: firebase.firestore.Firestore) {
    this._firestore = fs;
    this._batch = this._firestore.batch();
    this._batches = [];
  }

  /**
   * Set or overwrite data to new or existing document
   * @param doc Docuement to be set
   * @param data Data to be applied
   */
  set(
    doc: firebase.firestore.DocumentReference,
    data: firebase.firestore.UpdateData,
    options?: firebase.firestore.SetOptions,
  ): void {
    if (options) {
      this._batch.set(doc, data, options);
    } else {
      this._batch.set(doc, data);
    }
    this._size++;
    this.addBatchIfFull();
  }

  /**
   * Delete a document
   * @param doc document to be deleted
   */
  delete(doc: firebase.firestore.DocumentReference): void {
    this._batch.delete(doc);
    this._size++;
    this.addBatchIfFull();
  }

  /**
   * Apply an update to a document. The document MUST exist or the entire batch fails.
   * @param doc what doc to update
   * @param data the data that needs updating
   */
  update(doc: firebase.firestore.DocumentReference, data: firebase.firestore.UpdateData): void {
    this._batch.update(doc, data);
    this._size++;
    this.addBatchIfFull();
  }

  private addBatchIfFull(): void {
    if (this._size < 500) {
      return;
    }

    this._batches.push(this._batch);
    this.resetBatch();
  }

  async commit(): Promise<void> {
    // if any docs left in current batch, push to batch list
    if (this._size > 0) {
      this._batches.push(this._batch);
    }

    // if batch list has any batches
    if (this._batches.length > 0) {
      try {
        await Promise.all(this._batches.map(async (b): Promise<void> => b.commit()));
      } catch (e) {
        throw e;
      }
    }

    // resolve promises;
    try {
      await Promise.all(this._batches);
    } catch (e) {
      throw e;
    }

    this._batches = [];
    this.resetBatch();
  }

  private resetBatch(): void {
    this._size = 0;
    this._batch = this._firestore.batch();
  }
}
