import { Module } from 'vuex';
import to from 'await-to-js';
import BigNumber from 'bignumber.js';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyStorage, bloqifyFunctions, firebase } from '@/boot/firebase';
import { Counts, DataContainerStatus } from '@/models/Common';
import { Asset, Document } from '@/models/assets/Asset';
import { Investment } from '@/models/investments/Investment';
import { Valuation, ValuationStatus } from '@/models/assets/Valuation';
import { AssetEarning, InvestmentEarning } from '@/models/assets/Earnings';
import { AssetRepayment, InvestmentRepayment } from '@/models/assets/Repayments';
import { AssetCost, InvestmentCost } from '@/models/assets/Costs';
import { clientConfig } from '@/helpers/clientData';
import { PaymentProviderType } from '@/types/greenTea';
import { Vertebra, generateState, mutateState } from '../utils/skeleton';
import { generateFileMd5Hask } from '../utils/files';
import { safeDivision } from '../utils/numbers';
import { validateMimeTypes } from '../utils/validateMimeTypes';

const SET_ASSET = 'SET_ASSET';

const whitelabelConfig = clientConfig();

export const assetChecks = (asset: Asset): boolean => {
  const requiredFieldsConfig = whitelabelConfig.functionality.modules.asset.requiredFields;

  // Allow zeroes
  if (requiredFieldsConfig.some((field): boolean => !asset[field] && asset[field] !== 0)) {
    return false;
  }

  if (asset.dividendsFormat.length === 0) {
    return false;
  }

  return true;
};

export default {
  state: generateState(),
  mutations: {
    [SET_ASSET](
      state,
      { status, payload, operation }: { status: DataContainerStatus; payload?: unknown; operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async createAsset({ commit }, { asset }: { asset: Asset }): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'createAsset' });

      const dateNow = firebase.firestore.Timestamp.now();
      const storageRef = bloqifyStorage.ref();
      const assetRef = bloqifyFirestore.collection('assets').doc();
      const assetClone = { ...asset };
      const files: Record<string, File[]> = {};
      const storageChildren: { file: File; ref: firebase.storage.Reference }[] = [];
      const filesKeyNamesConfig = whitelabelConfig.functionality.modules.asset.fileKeyNames;

      // Building propper objects: asset (to send to the database) and files (to send to storage)
      // Setting up an array with all the files to be uploaded
      filesKeyNamesConfig.forEach((keyName): void => {
        assetClone[keyName] = assetClone[keyName].map((file: File): string => {
          const fullPath = `assets/${assetRef.id}/${file.name}`;

          storageChildren.push({
            file,
            ref: storageRef.child(fullPath),
          });

          // Creating / pushing files object
          if (files[keyName]) {
            files[keyName].push(file);
          } else {
            files[keyName] = [file];
          }

          // The asset object only needs the filename as a reference for the database
          return fullPath;
        });
      });

      // Now, after the files have been processed, check the MIME types
      // const [getAssetError, getAssetSuccess]
      const [mimeError, mimeSuccess] = await to(
        validateMimeTypes(
          storageChildren.map(({ file }): File => file),
          'ALL',
        ),
      );
      if (mimeError || mimeSuccess) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: Error('Error MIME file.'),
          operation: 'createAsset',
        });
      }
      // Uploading all files including hashes
      try {
        await Promise.all(
          storageChildren.map(async (child): Promise<firebase.storage.UploadTask> => {
            const md5Hash = await generateFileMd5Hask(child.file, true);

            return child.ref.put(child.file, { customMetadata: { md5Hash } });
          }),
        );
      } catch (e) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: 'createAsset' });
      }

      // Data fixing
      if (assetClone.startDateTime) {
        // @ts-expect-error - Missing types
        assetClone.startDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.startDateTime);
      }
      if (assetClone.endDateTime) {
        // @ts-expect-error - Missing types
        assetClone.endDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.endDateTime);
      }

      // @ts-expect-error - Missing types
      assetClone.createdDateTime = firebase.firestore.FieldValue.serverTimestamp();

      // @ts-expect-error - Missing types
      assetClone.updatedDateTime = firebase.firestore.FieldValue.serverTimestamp();

      assetClone.totalValueShares = safeDivision(assetClone.totalValueEuro, assetClone.sharePrice);
      assetClone.sharesAvailable = assetClone.totalValueShares;
      assetClone.totalValueEuro = assetClone.totalValueEuro || 0;
      assetClone.euroMin = assetClone.euroMin || 0;
      assetClone.sharePrice = assetClone.sharePrice || 0;
      assetClone.deleted = false;

      const [createError] = await to(assetRef.set(assetClone));
      if (createError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: createError, operation: 'createAsset' });
      }

      // only Equity will have valuations. This is strictly ZIB only right now. Might need further expansion or dynamic setup if any other
      // client is added with valuations logic
      const valuationRef = assetRef.collection('valuations').doc('initial');
      const valuationData: Valuation = {
        asset: assetRef,
        sharePrice: assetClone.sharePrice || 0,
        totalValueShares: assetClone.totalValueShares || 0,
        description: 'Initial valuation',
        deleted: false,
        status: ValuationStatus.Applied,
        applyDateTime: assetClone.startDateTime,
        createdDateTime: assetClone.startDateTime,
        updatedDateTime: dateNow,
      };
      const [createValuationError] = await to(valuationRef.set(valuationData));
      if (createValuationError) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: createValuationError,
          operation: 'createAsset',
        });
      }

      if (
        whitelabelConfig.paymentServiceProvider === PaymentProviderType.OPP &&
        whitelabelConfig.opp.source === 'asset'
      ) {
        const [createWalletError] = await to(
          bloqifyFunctions.httpsCallable('createAssetWalletId')({ assetId: assetRef.id }),
        );
        if (createWalletError) {
          return commit(SET_ASSET, {
            status: DataContainerStatus.Error,
            payload: createWalletError,
            operation: 'createAsset',
          });
        }
      }

      return commit(SET_ASSET, {
        status: DataContainerStatus.Success,
        payload: { id: assetRef.id },
        operation: 'createAsset',
      });
    },
    async updateAsset({ commit }, { asset }: { asset: Asset }): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'updateAsset' });

      const storageRef = bloqifyStorage.ref();
      const { id: assetId, ...assetClone } = asset;
      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const files: Record<string, File[]> = {};
      const storageChildren: { file: File; ref: firebase.storage.Reference }[] = [];
      const filesKeyNamesConfig = whitelabelConfig.functionality.modules.asset.fileKeyNames;
      if (
        whitelabelConfig.functionality.createAsset.additionalFilesForInvestmentsEnabled &&
        !filesKeyNamesConfig.includes('investmentFiles')
      ) {
        filesKeyNamesConfig.push('investmentFiles');
      }

      // Building propper objects: asset (to send to the database) and files (to send to storage)
      // Setting up an array with all the files to be uploaded
      filesKeyNamesConfig.forEach((keyName): void => {
        assetClone[keyName] = assetClone[keyName].map((file: File): string => {
          const fullPath = `assets/${assetRef.id}/${file.name}`;

          storageChildren.push({
            file,
            ref: storageRef.child(fullPath),
          });

          // Creating / pushing files object
          if (files[keyName]) {
            files[keyName].push(file);
          } else {
            files[keyName] = [file];
          }

          // The asset object only needs the filename as a reference for the database
          return fullPath;
        });
      });

      // Now, after the files have been processed, check the MIME types
      // const [mimeError, mimeSuccess] = await to(
      //   validateMimeTypes(
      //     storageChildren.map(({ file }): File => file),
      //     'ALL',
      //   ),
      // );
      // if (mimeError || mimeSuccess) {
      //   return commit(SET_ASSET, {
      //     status: DataContainerStatus.Error,
      //     payload: Error('Error MIME file.'),
      //     operation: 'updateAsset',
      //   });
      // }
      // The comparison of the md5Hash could have been done here via JavaScript (customMetadata.md5Hash) but it's also possible via
      // Firestore rules. The only caveat is that the error handling is not good at all, we cannot identify
      // what kind of error we are getting from the rules, only no permission.
      let storageResultsAndErrors: firebase.storage.UploadTaskSnapshot | firebase.functions.HttpsError[];
      try {
        // @ts-expect-error - Missing types (types are not correct, it does not support code 'storage/unauthorized')
        storageResultsAndErrors = await Promise.all(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          storageChildren.map(async (child): Promise<firebase.storage.UploadTask> => {
            const md5Hash = await generateFileMd5Hask(child.file, true);

            // Return all errors if there are any
            return child.ref.put(child.file, { customMetadata: { md5Hash } }).catch((err): Error => err);
          }),
        );
      } catch (e) {
        // Set error if there is any other kind of error than a FirebaseStorageError
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: 'updateAsset' });
      }

      // Check if there is any other FirebaseStorageError error than 'storage/unauthorized',
      // since it's the only one we have to check if the md5 exists (check rules)
      // @ts-expect-error - Missing types (types are not correct, it does not support code 'storage/unauthorized')
      const differentError = storageResultsAndErrors.some(
        (resultOrError): boolean => resultOrError.code && resultOrError.code !== 'storage/unauthorized',
      );
      if (differentError) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: Error('Error uploading files.'),
          operation: 'updateAsset',
        });
      }

      // Data fixing
      if (assetClone.startDateTime) {
        // @ts-expect-error - Missing types
        assetClone.startDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.startDateTime);
      }
      if (assetClone.endDateTime) {
        // @ts-expect-error - Missing types
        assetClone.endDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.endDateTime);
      }

      // @ts-expect-error - Missing types
      assetClone.updatedDateTime = firebase.firestore.FieldValue.serverTimestamp();

      let legacyClientName: string | undefined;
      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
          if (getAssetError || !getAssetSuccess?.exists) {
            throw getAssetError || Error('Asset not found.');
          }

          const dbAsset = getAssetSuccess?.data() as Asset;
          legacyClientName = dbAsset.clientName;

          const updateAssetObject: typeof assetClone = {
            ...assetClone,
            // @ts-expect-error - Wrong types
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          };

          if (
            whitelabelConfig.paymentServiceProvider === PaymentProviderType.OPP &&
            whitelabelConfig.opp.source === 'asset'
          ) {
            // Check if there has been any change in email from the assetClone to retrievedAsset
            if ((assetClone.email !== dbAsset.email || assetClone.name !== dbAsset.name) && dbAsset.walletId) {
              const [updateWalletError] = await to(
                bloqifyFunctions.httpsCallable('updateAssetWallet')({
                  assetId: assetRef.id,
                  projectName: assetClone.name,
                  email: assetClone.email,
                  walletId: dbAsset.walletId,
                }),
              );
              if (updateWalletError) {
                throw updateWalletError;
              }
            }
          }

          // We don't need to update shares fields if these fields remain the same
          if (
            dbAsset.totalValueEuro !== asset.totalValueEuro ||
            dbAsset.sharePrice !== asset.sharePrice ||
            dbAsset.startDateTime !== asset.startDateTime
          ) {
            const totalValueShares = safeDivision(assetClone.totalValueEuro, assetClone.sharePrice);

            updateAssetObject.totalValueShares = totalValueShares;
            updateAssetObject.sharesAvailable = new BigNumber(totalValueShares)
              .minus(new BigNumber(dbAsset.totalValueShares).minus(dbAsset.sharesAvailable))
              .toNumber();

            const valuationRef = assetRef.collection('valuations').doc('initial');
            const [getValuationError, getValuation] = await to(transaction.get(valuationRef));
            if (getValuationError) {
              throw getValuationError;
            }
            const valuation = {
              asset: assetRef,
              sharePrice: assetClone.sharePrice,
              totalValueShares: updateAssetObject.totalValueShares,
              deleted: false,
              description: 'Initial valuation',
              status: ValuationStatus.Applied,
              applyDateTime: assetClone.startDateTime, // We set the apply date to the start date of the asset
              createdDateTime: getValuation.get('createdDateTime') || assetClone.updatedDateTime,
              updatedDateTime: assetClone.updatedDateTime,
            } as Valuation;

            transaction.set(valuationRef, valuation);
          }

          transaction.update(assetRef, updateAssetObject);
        }),
      );
      if (transactionError) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'updateAsset',
        });
      }

      if (legacyClientName !== assetClone.clientName) {
        // Cascade update clientName in investments
        const investmentsRef = bloqifyFirestore
          .collection('investments')
          .where('asset', '==', assetRef)
          .where('deleted', '==', false);
        const [, getInvestmentsSuccess] = await to(investmentsRef.get());
        if (getInvestmentsSuccess) {
          const cascadeUpdate = await Promise.allSettled(
            getInvestmentsSuccess.docs.map(
              (investmentSnapshot): Promise<void> =>
                investmentSnapshot.ref.update({ clientName: assetClone.clientName }),
            ),
          );
          cascadeUpdate.forEach((result): void => {
            if (result.status === 'rejected') {
              // Let's trigger Sentry to have track of what did not update in the cascade
              throw result.reason;
            }
          });
        }

        // update all valuations with client name
        const valuationsRef = assetRef.collection('valuations');
        const [, getValuationsSuccess] = await to(valuationsRef.get());
        if (getValuationsSuccess) {
          const cascadeUpdate = await Promise.allSettled(
            getValuationsSuccess.docs.map(
              (valuationSnapshot): Promise<void> => valuationSnapshot.ref.update({ clientName: assetClone.clientName }),
            ),
          );
          cascadeUpdate.forEach((result): void => {
            if (result.status === 'rejected') {
              // Let's trigger Sentry to have track of what did not update in the cascade
              throw result.reason;
            }
          });
        }

        // update all projects with client name
        const projectsRef = assetRef.collection('projects');
        const [, getProjectsSuccess] = await to(projectsRef.get());
        if (getProjectsSuccess) {
          const cascadeUpdate = await Promise.allSettled(
            getProjectsSuccess.docs.map(
              (projectsSnapshot): Promise<void> => projectsSnapshot.ref.update({ clientName: assetClone.clientName }),
            ),
          );
          cascadeUpdate.forEach((result): void => {
            if (result.status === 'rejected') {
              // Let's trigger Sentry to have track of what did not update in the cascade
              throw result.reason;
            }
          });
        }
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, payload: asset, operation: 'updateAsset' });
    },
    async createWalletId({ commit }, { assetId }: { assetId: string }): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'createWalletId' });

      const [createWalletIdError, createWalletId] = await to(
        bloqifyFunctions.httpsCallable('createAssetWalletId')({ assetId }),
      );

      if (createWalletIdError) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: createWalletIdError,
          operation: 'createWalletId',
        });
      }

      return commit(SET_ASSET, {
        status: DataContainerStatus.Success,
        operation: 'createWalletId',
        payload: createWalletId.data.walletId,
      });
    },
    async handlePublishAssetById(
      { commit },
      { assetId, published }: { assetId: string; published: boolean },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'handlePublishAssetById' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const countsRef = bloqifyFirestore.collection('settings').doc('counts');

      const [updateAssetError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
          if (getAssetError || !getAssetSuccess?.exists || getAssetSuccess.get('deleted')) {
            throw getAssetError || Error('Asset does not exist');
          }
          if (whitelabelConfig.paymentServiceProvider === PaymentProviderType.OPP && published) {
            const asset = getAssetSuccess.data() as Asset;
            if (!asset.walletId) {
              throw Error('Asset need a wallet before being able to publish');
            }
          }

          const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

          transaction.update(assetRef, {
            published,
            updatedDateTime: serverTimestamp,
          });
          transaction.set(
            countsRef,
            {
              publishedAssets: firebase.firestore.FieldValue.increment(published ? 1 : -1),
              updatedDateTime: serverTimestamp,
            } as Counts,
            { merge: true },
          );
        }),
      );
      if (updateAssetError) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: updateAssetError,
          operation: 'handlePublishAssetById',
        });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'handlePublishAssetById' });
    },
    async handleDeleteAssetById({ commit }, { assetId }: { assetId: string }): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'handleDeleteAssetById' });

      const [updateAssetError] = await to(
        bloqifyFirestore.collection('assets').doc(assetId).update({
          deleted: true,
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        }),
      );
      if (updateAssetError) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: updateAssetError,
          operation: 'handleDeleteAssetById',
        });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'handleDeleteAssetById' });
    },
    async createPaymentRequestsForAsset(
      { commit },
      { assetId, lang }: { assetId: string; lang: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'requestAllPaymentsOperation' });

      const [requestPaymentsError] = await to(
        bloqifyFunctions.httpsCallable('requestPayment')({ mode: 'asset', assetId, lang }),
      );
      if (requestPaymentsError) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: requestPaymentsError,
          operation: 'requestAllPaymentsOperation',
        });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'requestAllPaymentsOperation' });
    },
    async deleteInvestmentFinancialsData(
      { commit },
      {
        financialsDataId,
        type,
        upperCaseType,
        investmentId,
      }: {
        financialsDataId: string;
        type: 'earnings' | 'repayments' | 'costs';
        upperCaseType: string;
        investmentId: string;
      },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: `deleteFinancialsData${upperCaseType}` });

      const timeNow = firebase.firestore.Timestamp.now();
      const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
      const financialRef = investmentRef.collection(type).doc(financialsDataId);
      const [deleteInvestmentFinancialsDataError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          // Fetch investment
          const [getInvestmentError, getInvestmentSuccess] = await to(transaction.get(investmentRef));
          if (getInvestmentError || !getInvestmentSuccess.exists) {
            throw Error('Error retrieving the investment.');
          }
          const investment = getInvestmentSuccess.data() as Investment;

          // Fetch asset
          const assetRef = investment.asset as firebase.firestore.DocumentReference;
          const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
          if (getAssetError || !getAssetSuccess.exists) {
            throw Error('Error retrieving the investment asset.');
          }
          const asset = getAssetSuccess.data() as Asset;

          // Fetch financial
          const [getFinancialsDataError, getFinancialsDataSuccess] = await to(transaction.get(financialRef));
          if (getFinancialsDataError || !getFinancialsDataSuccess.exists) {
            throw Error('Error retrieving the financials data.');
          }
          const data = getFinancialsDataSuccess.data() as InvestmentEarning | InvestmentRepayment | InvestmentCost;

          // Check repayments "to" field, can not delete a repayment from a transfer
          if (type === 'repayments') {
            if ('to' in data) {
              throw new Error('Cannot delete repayment from a transfer');
            }
          }

          // Check deleted
          if (data.deleted) {
            throw Error('Financials already deleted');
          }

          // Parent object ref
          const parentDocRef = ((data as InvestmentEarning).assetEarning ||
            (data as InvestmentRepayment).assetRepayment ||
            (data as InvestmentCost).assetCost) as firebase.firestore.DocumentReference;
          if (parentDocRef) {
            // Fetch parent object
            const [getParentFinancialError, getParentFinancialSuccess] = await to(transaction.get(parentDocRef));
            if (getParentFinancialError || !getParentFinancialSuccess.exists) {
              throw Error('Error retrieving the parent financials data.');
            }

            if (type === 'earnings' || type === 'costs') {
              // Update parent financial object
              const parentData = getParentFinancialSuccess.data() as AssetEarning | AssetCost;
              transaction.update(parentDocRef, {
                totalAmount: new BigNumber(parentData.totalAmount).minus(data.amount).toNumber(),
                updatedDateTime: timeNow,
              } as AssetEarning);
            }
            if (type === 'repayments') {
              // Update parent financial object
              const parentData = getParentFinancialSuccess.data() as AssetRepayment;
              transaction.update(parentDocRef, {
                totalAmount: new BigNumber(parentData.totalAmount).minus(data.amount).toNumber(),
                totalShares: new BigNumber(parentData.totalShares)
                  .minus((data as InvestmentRepayment).shares)
                  .toNumber(),
                updatedDateTime: timeNow,
              } as AssetRepayment);
            }
          }

          // Update financial object
          transaction.update(financialRef, {
            deleted: true,
            updatedDateTime: timeNow,
          } as InvestmentRepayment | InvestmentEarning | InvestmentCost);

          if (type === 'earnings') {
            // Update Investment
            transaction.update(investmentRef, {
              totalEuroEarnings: new BigNumber(investment.totalEuroEarnings || 0).minus(data.amount).toNumber(),
              updatedDateTime: timeNow,
            } as Investment);

            // Update Asset
            transaction.update(assetRef, {
              totalEuroEarnings: new BigNumber(asset.totalEuroEarnings || 0).minus(data.amount).toNumber(),
              updatedDateTime: timeNow,
            } as Asset);
          }
          if (type === 'costs') {
            // Update Investment
            transaction.update(investmentRef, {
              totalEuroCosts: new BigNumber(investment.totalEuroCosts || 0).minus(data.amount).toNumber(),
              updatedDateTime: timeNow,
            } as Investment);

            // Update Asset
            transaction.update(assetRef, {
              totalEuroCosts: new BigNumber(asset.totalEuroCosts || 0).minus(data.amount).toNumber(),
              updatedDateTime: timeNow,
            } as Asset);
          }
          if (type === 'repayments') {
            // Update Investment
            transaction.update(investmentRef, {
              totalEuroRepayments: new BigNumber(investment.totalEuroRepayments || 0).minus(data.amount).toNumber(),
              totalSharesRepayments: new BigNumber(investment.totalSharesRepayments || 0)
                .minus((data as InvestmentRepayment).shares)
                .toNumber(),
              updatedDateTime: timeNow,
            } as Investment);

            // Update Asset
            transaction.update(assetRef, {
              totalEuroRepayments: new BigNumber(asset.totalEuroRepayments || 0).minus(data.amount).toNumber(),
              totalSharesRepayments: new BigNumber(asset.totalSharesRepayments || 0)
                .minus((data as InvestmentRepayment).shares)
                .toNumber(),
              updatedDateTime: timeNow,
            } as Asset);
          }
        }),
      );
      if (deleteInvestmentFinancialsDataError) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: deleteInvestmentFinancialsDataError,
          operation: `deleteFinancialsData${upperCaseType}`,
        });
      }

      return commit(SET_ASSET, {
        status: DataContainerStatus.Success,
        operation: `deleteFinancialsData${upperCaseType}`,
      });
    },
    async updateInvestmentFinancialsData(
      { commit },
      {
        financialsDataId,
        type,
        upperCaseType,
        newData,
        investmentId,
      }: {
        investmentId: string;
        financialsDataId: string;
        type: 'earnings' | 'repayments' | 'costs';
        upperCaseType: string;
        newData: { amount: number; shares?: number; period: Date; transactionDate: Date };
      },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: `updateFinancialsData${upperCaseType}` });

      const timeNow = firebase.firestore.Timestamp.now();
      const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
      const financialRef = investmentRef.collection(type).doc(financialsDataId);
      const [updateInvestmentFinancialsDataError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          // Fetch investment
          const [getInvestmentError, getInvestmentSuccess] = await to(transaction.get(investmentRef));
          if (getInvestmentError || !getInvestmentSuccess.exists) {
            throw Error('Error retrieving the investment.');
          }
          const investment = getInvestmentSuccess.data() as Investment;

          // Fetch asset
          const assetRef = investment.asset as firebase.firestore.DocumentReference;
          const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
          if (getAssetError || !getAssetSuccess.exists) {
            throw Error('Error retrieving the investment asset.');
          }
          const asset = getAssetSuccess.data() as Asset;

          // Fetch financial
          const [getFinancialsDataError, getFinancialsDataSuccess] = await to(transaction.get(financialRef));
          if (getFinancialsDataError || !getFinancialsDataSuccess.exists) {
            throw Error('Error retrieving the financials data.');
          }
          const data = getFinancialsDataSuccess.data() as InvestmentEarning | InvestmentRepayment | InvestmentCost;
          const amountDiff = new BigNumber(data.amount).minus(newData.amount).toNumber();
          const sharesDiff = new BigNumber((data as InvestmentRepayment).shares || 0)
            .minus(newData.shares || 0)
            .toNumber();
          const paymentDate = firebase.firestore.Timestamp.fromDate(newData.period);

          // Check if repayment has 'to' field
          if (type === 'repayments') {
            if ('to' in data) {
              throw new Error('Cannot update repayment from a transfer');
            }
          }

          // Check deleted
          if (data.deleted) {
            throw Error('Financial is deleted');
          }

          // Parent object ref
          const parentDocRef = ((data as InvestmentEarning).assetEarning ||
            (data as InvestmentRepayment).assetRepayment ||
            (data as InvestmentCost).assetCost) as firebase.firestore.DocumentReference;
          if (parentDocRef) {
            // Fetch parent object
            const [getParentFinancialError, getParentFinancialSuccess] = await to(transaction.get(parentDocRef));
            if (getParentFinancialError || !getParentFinancialSuccess.exists) {
              throw Error('Error retrieving the parent financials data.');
            }

            if (type === 'earnings' || type === 'costs') {
              // Update parent financial object
              const parentData = getParentFinancialSuccess.data() as AssetEarning | AssetCost;
              transaction.update(parentDocRef, {
                totalAmount: new BigNumber(parentData.totalAmount).minus(amountDiff).toNumber(),
                updatedDateTime: timeNow,
              } as AssetEarning);
            }
            if (type === 'repayments') {
              // Update parent financial object
              const parentData = getParentFinancialSuccess.data() as AssetRepayment;
              transaction.update(parentDocRef, {
                totalAmount: new BigNumber(parentData.totalAmount).minus(amountDiff).toNumber(),
                totalShares: new BigNumber(parentData.totalShares).minus(sharesDiff).toNumber(),
                updatedDateTime: timeNow,
              } as AssetRepayment);
            }
          }

          if (type === 'earnings') {
            const recordDate = firebase.firestore.Timestamp.fromDate(newData.transactionDate);
            // Update financial object
            transaction.update(financialRef, {
              amount: newData.amount,
              paymentDateTime: paymentDate,
              earningDateTime: recordDate,
              updatedDateTime: timeNow,
            } as InvestmentEarning);

            // Update Investment
            transaction.update(investmentRef, {
              totalEuroEarnings: new BigNumber(investment.totalEuroEarnings || 0).minus(amountDiff).toNumber(),
              updatedDateTime: timeNow,
            } as Investment);

            // Update Asset
            transaction.update(assetRef, {
              totalEuroEarnings: new BigNumber(asset.totalEuroEarnings || 0).minus(amountDiff).toNumber(),
              updatedDateTime: timeNow,
            } as Asset);
          }
          if (type === 'costs') {
            // Update financial object
            transaction.update(financialRef, {
              amount: newData.amount,
              costDateTime: paymentDate,
              updatedDateTime: timeNow,
            } as InvestmentCost);

            // Update Investment
            transaction.update(investmentRef, {
              totalEuroCosts: new BigNumber(investment.totalEuroCosts || 0).minus(amountDiff).toNumber(),
              updatedDateTime: timeNow,
            } as Investment);

            // Update Asset
            transaction.update(assetRef, {
              totalEuroCosts: new BigNumber(asset.totalEuroCosts || 0).minus(amountDiff).toNumber(),
              updatedDateTime: timeNow,
            } as Asset);
          }
          if (type === 'repayments') {
            const recordDate = firebase.firestore.Timestamp.fromDate(newData.transactionDate);
            // Update financial object
            transaction.update(financialRef, {
              amount: newData.amount,
              shares: newData.shares,
              paymentDateTime: paymentDate,
              repaymentDateTime: recordDate,
              updatedDateTime: timeNow,
            } as InvestmentRepayment);

            // Update Investment
            transaction.update(investmentRef, {
              totalEuroRepayments: new BigNumber(investment.totalEuroRepayments || 0).minus(amountDiff).toNumber(),
              totalSharesRepayments: new BigNumber(investment.totalSharesRepayments || 0).minus(sharesDiff).toNumber(),
              updatedDateTime: timeNow,
            } as Investment);

            // Update Asset
            transaction.update(assetRef, {
              totalEuroRepayments: new BigNumber(asset.totalEuroRepayments || 0).minus(amountDiff).toNumber(),
              totalSharesRepayments: new BigNumber(asset.totalSharesRepayments || 0).minus(sharesDiff).toNumber(),
              updatedDateTime: timeNow,
            } as Asset);
          }
        }),
      );
      if (updateInvestmentFinancialsDataError) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: updateInvestmentFinancialsDataError,
          operation: `updateFinancialsData${upperCaseType}`,
        });
      }

      return commit(SET_ASSET, {
        status: DataContainerStatus.Success,
        operation: `updateFinancialsData${upperCaseType}`,
      });
    },
    async addDocumentsToAsset(
      { commit },
      { assetId, documents }: { assetId: string; documents: Document[] },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'addDocumentsToAsset' });

      const storageRef = bloqifyStorage.ref();
      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);

      // Fetch the asset from the database
      const [getAssetError, getAsset] = await to(assetRef.get());
      if (getAssetError || !getAsset.exists) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: getAssetError || Error('Asset not found'),
          operation: 'addDocumentsToAsset',
        });
      }

      const storageChildren: { file: File; ref: firebase.storage.Reference }[] = [];
      // Building propper objects: asset (to send to the database) and files (to send to storage)
      // Setting up an array with all the files to be uploaded
      const documentsParsed = documents.map(
        (doc): Document => ({
          ...doc,
          paths: doc.paths.map((file: string | { file: File; name: string }): string => {
            if (typeof file === 'string') {
              return file;
            }
            const fullPath = `assetPrivate/${assetRef.id}/${file.name}`;

            storageChildren.push({
              file: file.file,
              ref: storageRef.child(fullPath),
            });

            // The asset object only needs the filename as a reference for the database
            return fullPath;
          }),
        }),
      );

      // Now, after the files have been processed, check the MIME types
      const [mimeError, mimeSuccess] = await to(
        validateMimeTypes(
          storageChildren.map(({ file }): File => file),
          'ALL',
        ),
      );
      if (mimeError || mimeSuccess) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: Error('Error MIME file.'),
          operation: 'addDocumentsToAsset',
        });
      }

      // Uploading all files including hashes
      try {
        await Promise.all(
          storageChildren.map(async (child): Promise<firebase.storage.UploadTask> => {
            const md5Hash = await generateFileMd5Hask(child.file, true);

            return child.ref.put(child.file, { customMetadata: { md5Hash } });
          }),
        );
      } catch (e) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: 'addDocumentsToAsset' });
      }

      // Update asset documents
      const [updateAssetError] = await to(
        assetRef.update({
          documents: documentsParsed,
        } as Asset),
      );
      if (updateAssetError) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: updateAssetError,
          operation: 'addDocumentsToAsset',
        });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'addDocumentsToAsset' });
    },
  },
  getters: {
    getAssetTotalEuroInvested:
      (): ((asset: Asset) => number) =>
      (asset: Asset): number =>
        new BigNumber(asset.totalValueShares)
          .minus(asset.sharesAvailable)
          .times(asset.sharePrice)
          .decimalPlaces(2)
          .toNumber(),
  },
} as Module<Vertebra, State>;
