import moment from 'moment';
import { firebase } from '@/boot/firebase';
import { CustomTimestamp } from '@/models/CustomTimestamp';

/**
 * Formatting dates using moment
 *
 * Accepts an optional format parameter
 *
 * @example {{ myDate | transformDate }}
 * @example {{ myDate | transformDate('YYYY-MM-DD') }}
 */
export function transformDate(timestamp: firebase.firestore.Timestamp | Date, format?: string): string;
export function transformDate(timestamp: null, format?: string): string | null;
export function transformDate(
  timestamp: firebase.firestore.Timestamp | Date | null,
  format = 'DD-MM-YYYY',
): string | null {
  if (!timestamp) {
    return null;
  }
  // eslint-disable-next-line consistent-return
  return moment(timestamp instanceof firebase.firestore.Timestamp ? timestamp.toMillis() : timestamp).format(format);
}

/**
 * Transforms a CustomTimestamp object into a Date object
 * @param timestamp
 */
export const customTimestampToDate = (timestamp?: CustomTimestamp): Date | null => {
  if (!timestamp) {
    return null;
  }

  return new firebase.firestore.Timestamp(timestamp._seconds, timestamp._nanoseconds).toDate();
};

/**
 * Transforms a CustomTimestamp object into a Timestamp object
 * @param timestamp
 */
export const customTimestampToTimestamp = (timestamp?: CustomTimestamp): firebase.firestore.Timestamp | null => {
  if (!timestamp) {
    return null;
  }

  return new firebase.firestore.Timestamp(timestamp._seconds, timestamp._nanoseconds);
};

/**
 * Converts a Timestamp object into a Date object
 * @param date
 */
export const timestampToDate = (date?: firebase.firestore.Timestamp): Date | null => {
  if (!date) {
    return null;
  }

  return date.toDate();
};

/**
 * Converts a Date or Timestamp object based on brower's timezone to Date object UTC based
 * @param date
 */
export const convertLocalDateToUTC = (
  date?: Date | firebase.firestore.Timestamp,
  excludeTime?: boolean,
): Date | null => {
  if (!date) {
    return null;
  }

  const tempDate = date instanceof Date ? date : timestampToDate(date)!;

  const timeParams = [tempDate.getHours(), tempDate.getMinutes(), tempDate.getSeconds(), tempDate.getMilliseconds()];

  const newDate = Date.UTC(
    tempDate.getFullYear(),
    tempDate.getMonth(),
    tempDate.getDate(),
    ...(!excludeTime ? timeParams : []),
  );

  return new Date(newDate);
};

/**
 * Converts milliseconds or Timestamp object based on UTC date to a Date object based on current timezone
 * @param utcDate
 */
export const convertUTCToLocalDate = (utcDate: Date | number | firebase.firestore.Timestamp): Date | null => {
  if (!utcDate) {
    return null;
  }

  return utcDate instanceof firebase.firestore.Timestamp ? timestampToDate(utcDate) : new Date(utcDate);
};

export default {
  transformDate,
  customTimestampToDate,
  customTimestampToTimestamp,
  timestampToDate,
  convertLocalDateToUTC,
  convertUTCToLocalDate,
};
