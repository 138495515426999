// todo I think these types will become unnecessary
export interface PersonInterface {
  uid: string;
  email: string;
  createdAt?: string;
  lastSignInAt?: string;
}

export abstract class Person implements PersonInterface {
  uid: string;
  email: string;
  createdAt?: string;
  lastSignInAt?: string;

  /**
   * Boa constructor.
   */
  protected constructor(uid: string, email: string, createdAt?: string, lastSignInAt?: string) {
    this.uid = uid;
    this.email = email;
    this.createdAt = createdAt;
    this.lastSignInAt = lastSignInAt;
  }
}
