import { Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import { bloqifyFunctions } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { CreateBidInput, BidIds, ReserveBidInput, TransferInput, BidType } from '@/models/investments/Investment';
import { Vertebra, generateState, mutateState } from '@/store/utils/skeleton';

// Create sell bid
interface SellingBidInput extends CreateBidInput {
  action: 'sell';
}

// Create buy bid
interface BuyingBidInput extends CreateBidInput {
  action: 'buy';
}

// Create a buy bid and relate it with a selling bid
interface ReserveOperBidInput extends ReserveBidInput {
  action: 'reserve';
  autoComplete: boolean;
}

// Approve a buying and selling bid
interface CompleteBidInput extends BidIds {
  action: 'complete';
}

interface CancelBidInput extends BidIds {
  action: 'cancel';
  cancelPair: boolean;
}

interface TransferOperInput extends TransferInput {
  action: 'transfer';
}

const SET_BID = 'SET_BID';

export default {
  state: generateState(),
  mutations: {
    [SET_BID](
      state,
      { status, payload, operation }: { status: DataContainerStatus; payload?: unknown; operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async createSellingBid(
      { commit },
      { investorId, assetId, sharesAmount, euroAmount, expireDateTime }: Omit<SellingBidInput, 'action'>,
    ): Promise<void> {
      commit(SET_BID, { status: DataContainerStatus.Processing, operation: 'createSellingBid' });

      const data: SellingBidInput = {
        action: 'sell',
        type: BidType.Sell,
        investorId,
        assetId,
        sharesAmount,
        euroAmount,
        expireDateTime,
        mode: 'active',
      };
      const [bidOperationError] = await to(bloqifyFunctions.httpsCallable('bidOperation')(data));
      if (bidOperationError) {
        return commit(SET_BID, {
          status: DataContainerStatus.Error,
          payload: bidOperationError.message,
          operation: 'createSellingBid',
        });
      }

      return commit(SET_BID, { status: DataContainerStatus.Success, operation: 'createSellingBid' });
    },
    async createBuyingBid(
      { commit },
      { investorId, assetId, sharesAmount, euroAmount, expireDateTime }: Omit<BuyingBidInput, 'action'>,
    ): Promise<void> {
      commit(SET_BID, { status: DataContainerStatus.Processing, operation: 'createBuyingBid' });

      const data: BuyingBidInput = {
        action: 'buy',
        type: BidType.Buy,
        investorId,
        assetId,
        sharesAmount,
        euroAmount,
        expireDateTime,
        mode: 'active',
      };
      const [bidOperationError] = await to(bloqifyFunctions.httpsCallable('bidOperation')(data));
      if (bidOperationError) {
        return commit(SET_BID, {
          status: DataContainerStatus.Error,
          payload: bidOperationError.message,
          operation: 'createBuyingBid',
        });
      }

      return commit(SET_BID, { status: DataContainerStatus.Success, operation: 'createBuyingBid' });
    },
    async reserveBid({ commit }, { investorId, bid }: Omit<ReserveOperBidInput, 'action'>): Promise<void> {
      commit(SET_BID, { status: DataContainerStatus.Processing, operation: 'reserveBid' });

      const data: ReserveOperBidInput = {
        action: 'reserve',
        investorId,
        bid,
        autoComplete: false,
        flow: 'request',
      };
      const [bidOperationError] = await to(bloqifyFunctions.httpsCallable('bidOperation')(data));
      if (bidOperationError) {
        return commit(SET_BID, {
          status: DataContainerStatus.Error,
          payload: bidOperationError.message,
          operation: 'reserveBid',
        });
      }

      return commit(SET_BID, { status: DataContainerStatus.Success, operation: 'reserveBid' });
    },
    async completeBid({ commit }, { investmentId, bidId }: Omit<CompleteBidInput, 'action'>): Promise<void> {
      commit(SET_BID, { status: DataContainerStatus.Processing, operation: 'completeBid' });

      const data: CompleteBidInput = {
        action: 'complete',
        investmentId,
        bidId,
      };
      const [bidOperationError] = await to(bloqifyFunctions.httpsCallable('bidOperation')(data));
      if (bidOperationError) {
        return commit(SET_BID, {
          status: DataContainerStatus.Error,
          payload: bidOperationError.message,
          operation: 'completeBid',
        });
      }

      return commit(SET_BID, { status: DataContainerStatus.Success, operation: 'completeBid' });
    },
    async cancelBid({ commit }, { investmentId, bidId, cancelPair }: Omit<CancelBidInput, 'action'>): Promise<void> {
      commit(SET_BID, { status: DataContainerStatus.Processing, operation: 'cancelBid' });

      const data: CancelBidInput = {
        action: 'cancel',
        investmentId,
        bidId,
        cancelPair,
      };
      const [bidOperationError] = await to(bloqifyFunctions.httpsCallable('bidOperation')(data));
      if (bidOperationError) {
        return commit(SET_BID, {
          status: DataContainerStatus.Error,
          payload: bidOperationError.message,
          operation: 'cancelBid',
        });
      }

      return commit(SET_BID, { status: DataContainerStatus.Success, operation: 'cancelBid' });
    },
    async transferBidOper(
      { commit },
      {
        originInvestorId,
        destinyInvestorId,
        assetId,
        sharesAmount,
        euroAmount,
        transferDate,
      }: Omit<TransferOperInput, 'action'>,
    ): Promise<void> {
      commit(SET_BID, { status: DataContainerStatus.Processing, operation: 'transferBidOper' });

      const data: TransferOperInput = {
        action: 'transfer',
        originInvestorId,
        destinyInvestorId,
        assetId,
        sharesAmount,
        euroAmount,
        transferDate,
      };

      const [bidOperationError] = await to(bloqifyFunctions.httpsCallable('bidOperation')(data));
      if (bidOperationError) {
        return commit(SET_BID, {
          status: DataContainerStatus.Error,
          payload: bidOperationError.message,
          operation: 'transferBidOper',
        });
      }

      return commit(SET_BID, { status: DataContainerStatus.Success, operation: 'transferBidOper' });
    },
  },
  getters: {},
} as Module<Vertebra, State>;
