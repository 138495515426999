import { Module } from 'vuex';
import { State } from '@/models/State';
import asset from './asset';
import user from './user';
import identificationRequest from './identificationRequest';
import project from './project';
import payment from './payment';
import valuation from './valuation';
import pescheck from './pescheck';
import bid from './bid';
import { Vertebra } from '../utils/skeleton';
import earning from './earning';
import cost from './cost';
import repayment from './repayment';

export default {
  asset,
  user,
  bid,
  valuation,
  identificationRequest,
  payment,
  project,
  earning,
  cost,
  repayment,
  pescheck,
} as Record<string, Module<Vertebra, State>>;
