import { User, Investor, DataChangeRequest } from '@/models/users/User';
import { Vertebra } from '@/store/utils/skeleton';
import { AssetEarning } from '@/models/assets/Earnings';
import { AssetCost } from '@/models/assets/Costs';
import { Valuation } from '@/models/assets/Valuation';
import { DataContainer, DataContainerInterface, Settings, Counts, Backup } from './Common';
import { Manager, SpecialUser } from './manager/Manager';
import { CurrentManager, CurrentManagerInterface } from './manager/CurrentManager';
import { Filters, FilterInterface } from './Filters';
import { Asset } from './assets/Asset';
import { IdentificationRequest } from './identification-requests/IdentificationRequest';
import { Investment, Payment, DefaultBid } from './investments/Investment';
import { Trace } from './tracing/Trace';
import { Project } from './assets/Project';
import { NewsletterSubscribers } from './NewsletterSubscribers';
import { Pescheckv3PescheckDocument } from './identification-requests/pescheck';
import { AssetRepayment, InvestmentRepayment } from './assets/Repayments';

/**
 * Vuex state. Some rules:
 * - Datacontainer's type can only be a primitive, since store only allows primitives
 *
 * @see https://vuex.vuejs.org/guide/state.html
 */
export interface State {
  // Firestore native bindings
  manager: Manager | null;

  // Computed/derived data
  currentManager: DataContainerInterface<CurrentManagerInterface>;
  login: DataContainerInterface<unknown>;
  verifySMS: DataContainerInterface<unknown>;
  managerStatus: DataContainerInterface<string>;
  managerRole: DataContainerInterface<string>;
  investmentsOverview: DataContainerInterface<string>;
  managerRemoval: DataContainerInterface<unknown>;
  tracing: DataContainerInterface<Trace[]>;
  generalSettings: DataContainerInterface<Settings>;
  securitySettings: DataContainerInterface<string>;
  backups: DataContainerInterface<Backup[]>;
  restoreBackup: DataContainerInterface<unknown>;
  restoreBackupStatus: DataContainerInterface<unknown>;
  passwordReset: DataContainerInterface<string>;
  requestAllPaymentsOperation: DataContainerInterface<string>;
  approveSensitiveDataChange: DataContainerInterface<string>;
  rejectSensitiveDataChange: DataContainerInterface<string>;
  notification: DataContainerInterface<string>;
  sendTestEmails: DataContainerInterface<string>;
  updateMainSettings: DataContainerInterface<string>;
  pescheck: Vertebra | null;
  filters: FilterInterface;
  outlineMode: boolean;
  specialUsers: SpecialUser[];
  settings: Settings | null;
  restoration: unknown[] | null;

  openPayments: Payment[];
  loanPayments: Payment[];

  // The Refactor
  boundInvestments: Investment[];
  investments: Investment[];
  boundInvestment: Investment | null;
  boundPayments: Payment[];
  boundPayment: Payment | null;
  boundProjects: Project[];
  boundProject: Project;
  boundAssets: Asset[];
  boundAsset: Asset | null;
  boundPeschecks: Pescheckv3PescheckDocument[];
  boundEarnings: AssetEarning[];
  boundCosts: AssetCost[];
  boundRepayments: AssetRepayment[];
  boundInvestmentRepayments: InvestmentRepayment[];
  boundUsers: (User | Investor)[];
  boundIdentificationRequests: IdentificationRequest[];
  boundUser: (User & { nationality?: string }) | Investor | null;
  identificationRequests: IdentificationRequest[];
  boundCounts: Counts | null;
  boundNewsLetterSubscribers?: NewsletterSubscribers;
  asset: Vertebra | null;
  user: Vertebra | null;
  bid: Vertebra | null;
  payment: Vertebra | null;
  project: Vertebra | null;
  identificationRequest: Vertebra | null;
  dataChangeRequests: DataChangeRequest[];
  earning: Vertebra | null;
  cost: Vertebra | null;
  repayment: Vertebra | null;
  boundValuations: Valuation[];
  valuation: Vertebra | null;
  assetSettings: unknown;
  transfer: unknown;
  boundTransfer: unknown;
  boundPendingBids: DefaultBid[];
  boundPendingBid: DefaultBid | null;
  boundActiveBids: DefaultBid[];
  boundActiveBid: DefaultBid | null;
  boundClosedTransactions: DefaultBid[];
  boundClosedTransaction: DefaultBid | null;
}

export const generateInitialRootState = (): Record<string, unknown> => ({
  // Firestore native bindings
  manager: null,

  // Computed/derived data
  currentManager: new DataContainer<CurrentManager>(),
  login: new DataContainer<unknown>(),
  verifySMS: new DataContainer<unknown>(),
  managerStatus: new DataContainer<string>(),
  managerRole: new DataContainer<string>(),
  investmentsOverview: new DataContainer<string>(),
  managerRemoval: new DataContainer<unknown>(),
  tracing: new DataContainer<Trace[]>(),
  generalSettings: new DataContainer<Settings>(),
  securitySettings: new DataContainer<string>(),
  assetSettings: [],
  backups: new DataContainer<Backup[]>(),
  restoreBackup: new DataContainer<string>(),
  restoreBackupStatus: new DataContainer<string>(),
  passwordReset: new DataContainer<string>(),
  requestAllPaymentsOperation: new DataContainer<string>(),
  approveSensitiveDataChange: new DataContainer<string>(),
  rejectSensitiveDataChange: new DataContainer<string>(),
  sendTestEmails: new DataContainer<string>(),
  notification: new DataContainer<string>(),
  updateMainSettings: new DataContainer<string>(),
  filters: new Filters(),
  outlineMode: false,
  specialUsers: [],
  settings: null,
  restoration: null,
  dataChangeRequests: [],
  openPayments: [],
  loanPayments: [],
  boundInvestments: [],
  boundInvestment: null,
  boundPayments: [],
  boundPayment: null,
  boundAssets: [],
  boundIdentificationRequests: [],
  boundPeschecks: [],
  boundAsset: null,
  boundEarnings: [],
  boundCosts: [],
  boundRepayments: [],
  boundInvestmentRepayments: [],
  boundProjects: [],
  boundProject: null,
  boundUsers: [],
  boundUser: null,
  boundCounts: [],
  identificationRequests: [],
  boundValuations: [],
  uploadedRepayments: [],
  uploadedEarnings: [],
  boundPendingBids: [],
  boundPendingBid: null,
  boundActiveBids: [],
  boundActiveBid: null,
  boundClosedTransactions: [],
  boundClosedTransaction: null,
});
