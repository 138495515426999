import Vue, { VNode } from 'vue';
import { registerLocale } from 'i18n-iso-countries';
import { firebase } from '@/boot/firebase';
import router from '@/boot/router';
import store from '@/store';
import App from '@/App.vue';
import '../filters';
import { GreenTea } from '@/types/greenTea';
import { clientConfig } from '@/helpers/clientData';
import VueI18n from 'vue-i18n';
import { i18n } from './i18n';

/**
 * Register countries list in English locale
 *
 * @see https://github.com/michaelwittig/node-i18n-iso-countries
 */
registerLocale(require('i18n-iso-countries/langs/en.json'));
registerLocale(require('i18n-iso-countries/langs/nl.json'));

/**
 * Vue.
 *
 * @see https://vuejs.org/v2/api/
 */
let app: Vue;

Vue.config.productionTip = false;

declare module 'vue/types/vue' {
  interface Vue {
    $greenTea: GreenTea;
    $t: VueI18n['t'];
  }
}

/**
 * We are exporting the collection promises from the bindings
 * so we can know when and where the data is still loading.
 * We also have to define the levels so Vue can attach observability when needed.
 */
export const collections: Record<string, Promise<unknown> | null> = {
  settings: null,
  manager: null,
  restoration: null,
  specialUsers: null,
  dataChangeRequests: null,
};

const whitelabelConfig = clientConfig();

/**
 * Initialize Vue after Firebase is initialized. We use
 * Vuexfire to bind Firestore collections/documents.
 *
 * @see https://github.com/posva/vuexfire/tree/firestore
 */
export default function (authInstance: firebase.auth.Auth, firestoreInstance: firebase.firestore.Firestore): Vue {
  collections.settings = store.dispatch('bindFirestoreReference', {
    name: 'settings',
    ref: firestoreInstance.collection('settings').doc('admin'),
  });

  authInstance.onAuthStateChanged(async (user: firebase.User | null): Promise<void> => {
    if (!app) {
      Vue.prototype.$greenTea = {};

      // Adding global variables here
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(whitelabelConfig)) {
        Vue.prototype.$greenTea[`${key}`] = value;
      }
      /* eslint-disable no-new */
      app = new Vue({
        el: '#app',
        i18n,
        router,
        store,
        render: (h): VNode => h(App),
      });
    }

    const checkMultifactor = (currentUser: firebase.User): boolean =>
      currentUser.multiFactor.enrolledFactors.some(
        (multiFactor): boolean => multiFactor.factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID,
      );
    if (user && checkMultifactor(user)) {
      // Bind user data
      // @see https://github.com/posva/vuexfire/tree/firestore
      collections.manager = store.dispatch('bindFirestoreReference', {
        name: 'manager',
        ref: firestoreInstance.collection('specialUsers').doc(user.uid),
      });

      const currentUser = await user.getIdTokenResult();

      if (currentUser.claims.superadmin || currentUser.claims.admin) {
        collections.restoration = store.dispatch('bindFirestoreReference', {
          name: 'restoration',
          ref: firestoreInstance.collection('restoration'),
        });

        collections.specialUsers = store.dispatch('bindFirestoreReference', {
          name: 'specialUsers',
          ref: firestoreInstance.collection('specialUsers'),
        });

        collections.dataChangeRequests = store.dispatch('bindFirestoreReference', {
          name: 'dataChangeRequests',
          ref: firestoreInstance.collection('dataChangeRequests'),
        });
      }

      // Ensure user is always set in state, so also after page refresh of already logged
      // in session. To do so, we dispatch setAuthenticatedUser action, which commits a mutation.
      void store.dispatch('setAuthenticatedUser', { user });
    } else {
      // Unbinds all data that is binded in the collections object at logout
      Object.keys(collections).forEach((name: string): void => {
        if (collections[name] !== null && name !== 'settings') {
          void store.dispatch('unbindFirestoreReference', { name });
        }
      });
    }
  });

  return app;
}
