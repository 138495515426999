import '@/boot/icons';
import '@/boot/dashkit';
import '@/boot/charts';
import '@/boot/layouts';
import '@/boot/i18n';
import '@/boot/utilities';
import '@/boot/components';
import { bloqifyAuth, bloqifyFirestore } from '@/boot/firebase';
import bootVue from '@/boot/vue';
import '@/scss/global/global.scss';

// Boot Vue by passing along Firebase Auth
bootVue(bloqifyAuth, bloqifyFirestore);
