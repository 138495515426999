import BigNumber from 'bignumber.js';

/**
 * Round number to the specified number of decimals following the required approach
 * @param num number - number to round
 * @param scale number - number of decimals to round to
 * @param formula BigNumber.RoundingMode - Rounding formula
 */
export const roundNumber = (num: number, scale: number, formula: BigNumber.RoundingMode): number =>
  new BigNumber(num).dp(scale, formula).toNumber();

/**
 * Division with precission, in case of wrong inputs, 0 denominator or other wrong cases the result will be 0
 * @param numerator: number - numerator of the division
 * @param denominator: number - denominator of the division
 * @returns: number - result
 */
export const safeDivision = (numerator: number, denominator: number): number => {
  // We divide the values with BigNumber
  const result = new BigNumber(numerator).dividedBy(denominator).toNumber();
  // Verify if the result is either NaN or Infinity
  const invalid = isNaN(result) || !isFinite(result);
  // Retun 0 if invalid, result otherwise
  return invalid ? 0 : result;
};
