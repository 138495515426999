import firebase from 'firebase/compat/app';

/**
 * Describing the iR status.
 */
export enum IdentificationRequestStatus {
  Initial = 'initial',
  Approved = 'approved',
  Rejected = 'rejected',
  None = 'none',
}

export enum UserGender {
  Male = 'm',
  Female = 'f',
}

export enum UserExperience {
  Experienced = 'experienced',
  Unexperienced = 'unexperienced',
}

/**
 * Basic iR interface.
 */
export interface PrivateIdentification {
  id?: string;
  bankAccount: string;
  bankAccountOwner?: string;
  city: string;
  initials?: string;
  country: string;
  dateOfBirth: firebase.firestore.Timestamp;
  passportExpirationDate?: firebase.firestore.Timestamp;
  passportSecondExpirationDate?: firebase.firestore.Timestamp;
  gender: UserGender;
  houseNumber: string;
  message?: string;
  name: string;
  nationality: string;
  passport: string;
  passportSecond?: string;
  postalCode: string;
  status: IdentificationRequestStatus;
  streetAddress: string;
  surname: string;
  middleName?: string;
  telephone?: string;
  deleted: boolean;
  subscribeNewsLetter?: boolean;
  receivePhysicalMailings?: boolean;
  userExperience?: UserExperience;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime: firebase.firestore.Timestamp;
}

/**
 * BusinessIdentification type.
 */
export interface BusinessIdentification extends PrivateIdentification {
  companyId?: string;
  kvk: number;
  kvkImage: string;
}

export type IdentificationRequest = PrivateIdentification | BusinessIdentification;

/* Second investor identification */
export interface SecondInvestorIdentification {
  initials?: string;
  dateOfBirth?: firebase.firestore.Timestamp;
  passportExpirationDate?: firebase.firestore.Timestamp;
  gender?: UserGender;
  name?: string;
  nationality?: string;
  socialSecurityNumber?: string;
  passport?: string;
  surname?: string;
}

/**
 * Type guard to check the type of Identification Request.
 * @param iRequest basic Identification Request interfaces.
 */
export const isBusiness = (iRequest: IdentificationRequest): iRequest is BusinessIdentification =>
  !!(iRequest as BusinessIdentification).kvk;

// Identification Request Converted created in order to just load from firestore the required fields
export class IdentificationRequestConverter {
  constructor(readonly status: string) {
    this.status = status;
  }
}

export const identificationRequestConverter = {
  toFirestore(identificationRequest): firebase.firestore.DocumentData {
    return {
      status: identificationRequest.name,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): IdentificationRequestConverter {
    const data = snapshot.data(options);
    return new IdentificationRequestConverter(data.status);
  },
};
