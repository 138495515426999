import { filetypemime } from 'magic-bytes.js';

const mimeLists = {
  PDF: ['application/pdf'],
  EXCEL: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv'],
  IMAGE: ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/jpg'],
  ALL: [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/csv',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/jpg',
  ],
};

/**
 * Validates the MIME types of files based on the specified category.
 * @param {File[]} storageFiles - An array of File objects representing the files to validate.
 * @param {'PDF' | 'EXCEL' | 'IMAGE' | 'ALL'} category - The category of files to validate.
 * @returns {Promise<boolean>} A Promise that resolves to true if any file fails MIME type validation, false otherwise.
 * @throws {Error} If an invalid category is provided.
 */

export async function validateMimeTypes(
  storageFiles: File[],
  category: 'PDF' | 'EXCEL' | 'IMAGE' | 'ALL',
): Promise<boolean> {
  // Retrieve the list of accepted MIME types based on the provided category
  const acceptedMime = mimeLists[category];
  // Throw an error if the provided category is invalid
  if (!acceptedMime) {
    throw new Error('Invalid category');
  }
  // Create an array of promises for each file to asynchronously validate their MIME types
  const validationPromises = storageFiles.map(
    (child): Promise<boolean> =>
      new Promise<boolean>((resolve): void => {
        const reader = new FileReader();
        reader.onloadend = (): void => {
          const bytes = new Uint8Array(reader.result as ArrayBuffer);
          const mimeTypes = filetypemime(Array.from(bytes));
          // Resolve the promise with true if the MIME type is not accepted, false otherwise
          resolve(!mimeTypes || mimeTypes.length === 0 || !acceptedMime.includes(mimeTypes[0]));
        };
        reader.readAsArrayBuffer(child.slice(0, 100));
      }),
  );
  // Wait for all validation promises to resolve
  const results = await Promise.all(validationPromises);
  // Determine if any files failed MIME type validation
  const hasMimeError = results.some((result): boolean => result);
  return hasMimeError;
}
