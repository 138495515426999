import { ActionContext, Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyFunctions, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { UserData } from '@/models/identification-requests/pescheck';
import { generateState, mutateState, Vertebra } from '../utils/skeleton';

const SET_PESCHECK = 'SET_PESCHECK';

export default {
  state: generateState(),
  mutations: {
    [SET_PESCHECK](
      state,
      { status, payload, operation }: { status: DataContainerStatus; payload?: unknown; operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async requestPescheck(
      { commit }: ActionContext<Vertebra, State>,
      { pescheck, investorId }: { pescheck: UserData; investorId: string },
    ): Promise<void> {
      commit(SET_PESCHECK, { status: DataContainerStatus.Processing, operation: 'requestPescheck' });

      const [updatePescheckError] = await to(
        bloqifyFunctions.httpsCallable('requestPescheck')({
          userData: {
            email: pescheck.email,
            last_name: pescheck.last_name,
            first_name: pescheck.first_name,
            watchlist_date_of_birth: pescheck.watchlist_date_of_birth,
            ...(pescheck.watchlist_notes && { watchlist_notes: pescheck.watchlist_notes }),
          },
          investorId,
        }),
      );

      if (updatePescheckError) {
        return commit(SET_PESCHECK, {
          status: DataContainerStatus.Error,
          payload: updatePescheckError,
          operation: 'requestPescheck',
        });
      }

      return commit(SET_PESCHECK, {
        status: DataContainerStatus.Success,
        payload: pescheck,
        operation: 'requestPescheck',
      });
    },
    async pescheckScreeningReport({ commit }: ActionContext<Vertebra, State>, screeningId: string): Promise<void> {
      commit(SET_PESCHECK, { status: DataContainerStatus.Processing, operation: 'pescheckScreeningReport' });

      const [pescheckScreeningError, pescheckScreening] = await to(
        bloqifyFunctions.httpsCallable('pescheckScreeningReport')({ screeningId }),
      );

      if (pescheckScreeningError) {
        return commit(SET_PESCHECK, {
          status: DataContainerStatus.Error,
          payload: pescheckScreeningError,
          operation: 'pescheckScreeningReport',
        });
      }

      return commit(SET_PESCHECK, {
        status: DataContainerStatus.Success,
        payload: pescheckScreening?.data,
        operation: 'pescheckScreeningReport',
      });
    },
    async updatePescheckHitPep(
      { commit }: ActionContext<Vertebra, State>,
      { uid, isFalsePositivePep, pepRationale }: { uid: string; isFalsePositivePep: string; pepRationale: string },
    ): Promise<void> {
      commit(SET_PESCHECK, { status: DataContainerStatus.Processing, operation: 'updatePescheckHitPep' });

      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const pescheckRef = bloqifyFirestore.collection('pescheckv3_data').doc(uid);

          const [getPescheckError, getPescheck] = await to(transaction.get(pescheckRef));

          if (getPescheckError) {
            throw getPescheckError;
          }

          const pescheck = getPescheck?.data();

          transaction.update(pescheckRef, {
            finalResult: {
              ...pescheck?.finalResult,
              isFalsePositivePep,
              pepRationale,
            },
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }),
      );

      if (transactionError) {
        return commit(SET_PESCHECK, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'updatePescheckHitPep',
        });
      }
      return commit(SET_PESCHECK, {
        status: DataContainerStatus.Success,
        operation: 'updatePescheckHitPep',
      });
    },
    async updatePescheckHitScreening(
      { commit }: ActionContext<Vertebra, State>,
      {
        uid,
        isFalsePositiveScreening,
        screeningRationale,
      }: { uid: string; isFalsePositiveScreening: string; screeningRationale: string },
    ): Promise<void> {
      commit(SET_PESCHECK, { status: DataContainerStatus.Processing, operation: 'updatePescheckHitScreening' });

      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const pescheckRef = bloqifyFirestore.collection('pescheckv3_data').doc(uid);

          const [getPescheckError, getPescheck] = await to(transaction.get(pescheckRef));
          if (getPescheckError) {
            throw getPescheckError;
          }

          const pescheck = getPescheck?.data();

          transaction.update(pescheckRef, {
            finalResult: {
              ...pescheck?.finalResult,
              isFalsePositiveScreening,
              screeningRationale,
            },
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }),
      );

      if (transactionError) {
        return commit(SET_PESCHECK, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'updatePescheckHitScreening',
        });
      }
      return commit(SET_PESCHECK, {
        status: DataContainerStatus.Success,
        operation: 'updatePescheckHitScreening',
      });
    },
  },
} as Module<Vertebra, State>;
