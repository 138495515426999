import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
// Solid icons
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons/faBinoculars';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faFutbol } from '@fortawesome/free-solid-svg-icons/faFutbol';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons/faMailBulk';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faMoneyCheck } from '@fortawesome/free-solid-svg-icons/faMoneyCheck';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faShapes } from '@fortawesome/free-solid-svg-icons/faShapes';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faVectorSquare } from '@fortawesome/free-solid-svg-icons/faVectorSquare';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons/faArrowsAltH';
import { faWallet } from '@fortawesome/free-solid-svg-icons/faWallet';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPenSquare } from '@fortawesome/free-solid-svg-icons/faPenSquare';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons/faFolderOpen';
// Brands icons
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';

// Add icons to library
library.add(
  // @ts-expect-error - ToDo: wrong types
  faBell,
  faBriefcase,
  faBinoculars,
  faBuilding,
  faCalendar,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faCloud,
  faCog,
  faComment,
  faExclamationCircle,
  faQuestionCircle,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faFutbol,
  faGithub,
  faInfoCircle,
  faMailBulk,
  faMapMarkerAlt,
  faMinus,
  faMoneyCheck,
  faPlusCircle,
  farCheckCircle,
  faSearch,
  faShapes,
  faSignOutAlt,
  faSpinner,
  faStar,
  faTachometerAlt,
  faThLarge,
  faTimes,
  faTimesCircle,
  faTrash,
  faUser,
  faUserEdit,
  faUsers,
  faVectorSquare,
  faFile,
  faCopy,
  faMinusCircle,
  faChartLine,
  faArrowsAltH,
  faWallet,
  faList,
  faEllipsisH,
  faEnvelope,
  faPenSquare,
  faFolderOpen,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

export { FontAwesomeIcon, library };
