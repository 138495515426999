import { Module } from 'vuex';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyStorage, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import BigNumber from 'bignumber.js';
import to from 'await-to-js';
import moment from 'moment';
import { Asset, ExportTableDataType } from '@/models/assets/Asset';
import { Valuation } from '@/models/assets/Valuation';
import { AssetRepayment, InvestmentRepayment } from '@/models/assets/Repayments';
import { Investment } from '@/models/investments/Investment';
import { Vertebra, generateState, mutateState } from '../utils/skeleton';
import { CustomBatch } from '../utils/customBatch';
import { ImportData, generateFileMd5Hask } from '../utils/files';
import BatchInstance from './batches';

export interface RepaymentActionParam {
  assetId: string;
  repaymentId: string;
}

export interface AddRepaymentParam {
  amount: number;
  shares: number;
  assetId: string;
  paymentDate: Date;
  date: Date;
  dividendFormat?: number;
  repaymentAmounts: { investmentId: string; amount: number; shares: number }[];
  description?: string;
}

export interface BulkAddRepaymentParam {
  assetId: string;
  uploadedFile: File;
  data: ExportTableDataType[];
}

const SET_REPAYMENTS = 'SET_REPAYMENTS';

export default {
  state: generateState(),
  mutations: {
    [SET_REPAYMENTS](
      state,
      { status, payload, operation }: { status: DataContainerStatus; payload?: unknown; operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addRepayment(
      { commit },
      { assetId, paymentDate, date, amount, shares, repaymentAmounts, dividendFormat, description }: AddRepaymentParam,
    ): Promise<void> {
      commit(SET_REPAYMENTS, { status: DataContainerStatus.Processing, operation: 'addRepayment' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const paymentDateTime = firebase.firestore.Timestamp.fromDate(paymentDate);
      const repaymentDate = firebase.firestore.Timestamp.fromDate(date);
      const [createRepaymentError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const timeNow = firebase.firestore.Timestamp.now();
          const transactionBatch = new CustomBatch(transaction);

          // Fetch asset
          const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
          if (getAssetError || !getAssetSuccess.exists) {
            throw Error('Error retrieving the asset.');
          }
          const asset = getAssetSuccess.data() as Asset;

          let dividendsFormat: Asset['dividendsFormat'][0]['contents'] | undefined;
          if (dividendFormat) {
            dividendsFormat = asset.dividendsFormat.find((df): boolean => df.contents[1] === dividendFormat)?.contents;
          }

          // Update asset totals
          transactionBatch.addOperation({
            ref: assetRef,
            data: {
              totalEuroRepayments: new BigNumber(asset.totalEuroRepayments || 0).plus(amount).toNumber(),
              totalSharesRepayments: new BigNumber(asset.totalSharesRepayments || 0).plus(shares).toNumber(),
              updatedDateTime: timeNow,
            } as Asset,
          });

          // Create asset repayment data
          const assetRepaymentRef = assetRef.collection('assetRepayments').doc();
          const assetRepaymentData: AssetRepayment = {
            asset: assetRef,
            deleted: false,
            totalAmount: amount,
            totalShares: shares,
            repaymentDateTime: repaymentDate,
            paymentDateTime,
            createdDateTime: timeNow,
            updatedDateTime: timeNow,
            description: description || '',
          };
          transactionBatch.addOperation({
            ref: assetRepaymentRef,
            data: assetRepaymentData,
          });

          // For each received investment amount
          const [investmentRepaymentError] = await to(
            Promise.all(
              repaymentAmounts.map(async (repaymentAmount): Promise<void> => {
                const investmentRef = bloqifyFirestore.collection('investments').doc(repaymentAmount.investmentId);
                const [getInvestmentError, getInvestment] = await to(transaction.get(investmentRef));
                if (getInvestmentError || !getInvestment.exists) {
                  throw Error('Error retrieving the investment.');
                }
                const investment = getInvestment.data() as Investment;

                // Update investment total
                transactionBatch.addOperation({
                  ref: investmentRef,
                  data: {
                    totalEuroRepayments: new BigNumber(investment.totalEuroRepayments || 0)
                      .plus(repaymentAmount.amount)
                      .toNumber(),
                    totalSharesRepayments: new BigNumber(investment.totalSharesRepayments || 0)
                      .plus(repaymentAmount.shares)
                      .toNumber(),
                    updatedDateTime: timeNow,
                  } as Investment,
                });

                // Create investment repayment data
                const investmentRepaymentRef = investmentRef.collection('repayments').doc();
                const investmentRepaymentData: InvestmentRepayment = {
                  asset: assetRef,
                  assetRepayment: assetRepaymentRef,
                  investment: investmentRef,
                  investor: investment.investor,
                  deleted: false,
                  amount: repaymentAmount.amount,
                  shares: repaymentAmount.shares,
                  repaymentDateTime: repaymentDate,
                  paymentDateTime,
                  createdDateTime: timeNow,
                  updatedDateTime: timeNow,
                  description: description || '',
                  ...(dividendsFormat && { dividendsFormat }),
                };
                transactionBatch.addOperation({
                  ref: investmentRepaymentRef,
                  data: investmentRepaymentData,
                });
              }),
            ),
          );
          if (investmentRepaymentError) {
            throw investmentRepaymentError;
          }

          // COMMIT
          transactionBatch.commit();
        }),
      );
      if (createRepaymentError) {
        return commit(SET_REPAYMENTS, {
          status: DataContainerStatus.Error,
          payload: createRepaymentError,
          operation: 'addRepayment',
        });
      }

      return commit(SET_REPAYMENTS, { status: DataContainerStatus.Success, operation: 'addRepayment' });
    },
    async bulkAddRepayment({ commit }, { assetId, uploadedFile, data }: BulkAddRepaymentParam): Promise<void> {
      commit(SET_REPAYMENTS, { status: DataContainerStatus.Processing, operation: 'bulkAddRepayment' });
      const batch = new BatchInstance(bloqifyFirestore);
      const timeNow = firebase.firestore.Timestamp.now();
      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const assetRepaymentRef = assetRef.collection('assetRepayments').doc();
      try {
        // Fetch asset data
        const [getAssetError, getAssetSuccess] = await to(assetRef.get());
        if (getAssetError || !getAssetSuccess.exists) {
          throw Error('Error retrieving the asset.');
        }
        const asset = getAssetSuccess.data() as Asset;

        // Fetch asset valuations
        const [getValuationsError, getValuations] = await to(
          assetRef.collection('valuations').where('deleted', '==', false).orderBy('applyDateTime', 'desc').get(),
        );
        if (getValuationsError) {
          throw Error('Error retrieving valuations.');
        }
        const valuations = getValuations.docs.map((row): Valuation => row.data() as Valuation);

        // Prepare doc path
        const fullPath = `assets/${assetRef.id}/${'repayments'}/${moment().format('YYYY_MM_DD_HH_mm_ss')}_${uploadedFile.name}`;

        // Uploading all files including hashes
        const md5Hash = await generateFileMd5Hask(uploadedFile, true);
        bloqifyStorage.ref().child(fullPath).put(uploadedFile, { customMetadata: { md5Hash } });

        // Calculate shares amounts and transform dates to firestore timestamp
        const processedData = data.map((row): ImportData & { shares: number } => {
          const payDateTimestamp = firebase.firestore.Timestamp.fromMillis(
            moment(row.paymentDate, 'DD/MM/YYYY').valueOf(),
          );
          const transactionDateTimestamp = firebase.firestore.Timestamp.fromMillis(
            moment(row.transactionDate, 'DD/MM/YYYY').valueOf(),
          );
          const sharePrice =
            valuations.find((valuation): boolean => valuation.applyDateTime < payDateTimestamp)?.sharePrice ||
            asset.sharePrice;
          return {
            amount: Number(row.amount),
            customId: Number(row.investorId),
            dividendsFormat: row.dividendType || '',
            description: row.description || '',
            transactionDate: transactionDateTimestamp,
            period: payDateTimestamp,
            shares: new BigNumber(row.amount).dividedBy(sharePrice).toNumber(),
          };
        });

        // Upload docs
        const [processDataError] = await to(
          Promise.all(
            processedData.map(async (row): Promise<void> => {
              // Fetch investor
              const [getInvestorsError, getInvestorsSuccess] = await to(
                bloqifyFirestore.collection('investors').where('customId', '==', row.customId).limit(1).get(),
              );
              if (getInvestorsError || getInvestorsSuccess.empty) {
                throw Error('Error retrieving the investor.');
              }
              const investorRef = getInvestorsSuccess.docs[0].ref;

              // Fetch investment
              const [getInvestmentsError, getInvestmentsSuccess] = await to(
                bloqifyFirestore
                  .collection('investments')
                  .where('deleted', '==', false)
                  .where('investor', '==', investorRef)
                  .where('asset', '==', assetRef)
                  .limit(1)
                  .get(),
              );
              if (getInvestmentsError || getInvestmentsSuccess.empty) {
                throw (
                  getInvestmentsError ||
                  Error(`Error, investor ${row.customId} doesnt have an investment in this asset`)
                );
              }

              const investmentRef = getInvestmentsSuccess.docs[0].ref;
              const investmentData = getInvestmentsSuccess.docs[0].data() as Investment;

              // Update Investment totals
              batch.set(
                investmentRef,
                {
                  totalEuroRepayments: new BigNumber(investmentData.totalEuroRepayments || 0)
                    .plus(row.amount)
                    .toNumber(),
                  totalSharesRepayments: new BigNumber(investmentData.totalSharesRepayments || 0)
                    .plus(row.shares)
                    .toNumber(),
                  updatedDateTime: timeNow,
                } as Investment,
                { merge: true },
              );

              let dividendsFormat: Asset['dividendsFormat'][0]['contents'] | undefined;
              if (row.dividendsFormat) {
                dividendsFormat = asset.dividendsFormat.find(
                  (df): boolean => df.contents[0].toUpperCase() === row.dividendsFormat.toString().toUpperCase(),
                )?.contents;
              }

              // Set investment repayment
              const investmentRepaymentRef = investmentRef.collection('repayments').doc();
              const investmentRepaymentData: InvestmentRepayment = {
                asset: assetRef,
                assetRepayment: assetRepaymentRef,
                investment: investmentRef,
                investor: investorRef,
                deleted: false,
                amount: row.amount,
                shares: row.shares,
                repaymentDateTime: row.transactionDate,
                paymentDateTime: row.period,
                createdDateTime: timeNow,
                updatedDateTime: timeNow,
                description: row.description || '',
                ...(dividendsFormat && { dividendsFormat }),
              };
              batch.set(investmentRepaymentRef, investmentRepaymentData);
            }),
          ),
        );
        if (processDataError) {
          throw processDataError;
        }

        // Add all repayments
        const totals = processedData.reduce(
          (acum, next): { amount: BigNumber; shares: BigNumber } => {
            acum.amount = acum.amount.plus(next.amount);
            acum.shares = acum.shares.plus(next.shares);
            return acum;
          },
          { amount: new BigNumber(0), shares: new BigNumber(0) },
        );

        // Set asset repayment
        const assetRepaymentData: AssetRepayment = {
          asset: assetRef,
          deleted: false,
          totalAmount: totals.amount.toNumber(),
          totalShares: totals.shares.toNumber(),
          repaymentDateTime: timeNow,
          createdDateTime: timeNow,
          updatedDateTime: timeNow,
          file: fullPath,
          description: '',
        };
        batch.set(assetRepaymentRef, assetRepaymentData);

        // Update asset totals
        batch.set(
          assetRef,
          {
            totalEuroRepayments: new BigNumber(asset.totalEuroRepayments || 0)
              .plus(totals.amount.toNumber())
              .toNumber(),
            totalSharesRepayments: new BigNumber(asset.totalSharesRepayments || 0)
              .plus(totals.shares.toNumber())
              .toNumber(),
            updatedDateTime: timeNow,
          } as Asset,
          { merge: true },
        );

        // Commit
        const [commitError] = await to(batch.commit());
        if (commitError) {
          throw commitError;
        }
      } catch (e) {
        return commit(SET_REPAYMENTS, { status: DataContainerStatus.Error, payload: e, operation: 'bulkAddRepayment' });
      }
      return commit(SET_REPAYMENTS, { status: DataContainerStatus.Success, operation: 'bulkAddRepayment' });
    },
    async deleteRepayment({ commit }, { assetId, repaymentId }: RepaymentActionParam): Promise<void> {
      commit(SET_REPAYMENTS, { status: DataContainerStatus.Processing, operation: 'deleteRepayment' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const assetRepaymentRef = assetRef.collection('assetRepayments').doc(repaymentId);
      const [deleteRepaymentError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const timeNow = firebase.firestore.Timestamp.now();
          const transactionBatch = new CustomBatch(transaction);
          // Fetch asset
          const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
          if (getAssetError || !getAssetSuccess.exists) {
            throw Error('Error retrieving the asset.');
          }
          const asset = getAssetSuccess.data() as Asset;

          // Fetch repayment
          const [getAssetRepaymentError, getAssetRepaymentSuccess] = await to(transaction.get(assetRepaymentRef));
          if (getAssetRepaymentError || !getAssetRepaymentSuccess.exists) {
            throw Error('Error retrieving the repayment.');
          }
          const assetRepayment = getAssetRepaymentSuccess.data() as AssetRepayment;

          // Check repayment not deleted
          if (assetRepayment.deleted) {
            throw Error('Repayment already erased.');
          }

          // Update asset totals
          transactionBatch.addOperation({
            ref: assetRef,
            data: {
              totalEuroRepayments: new BigNumber(asset.totalEuroRepayments || 0)
                .minus(assetRepayment.totalAmount)
                .toNumber(),
              totalSharesRepayments: new BigNumber(asset.totalSharesRepayments || 0)
                .minus(assetRepayment.totalShares)
                .toNumber(),
              updatedDateTime: timeNow,
            } as Asset,
          });

          // Update asset repayment
          transactionBatch.addOperation({
            ref: assetRepaymentRef,
            data: {
              deleted: true,
              updatedDateTime: timeNow,
            } as AssetRepayment,
          });

          // Fetch investments repayments
          const [getInvestmentRepaymentsError, getInvestmentRepayments] = await to(
            bloqifyFirestore.collectionGroup('repayments').where('assetRepayment', '==', assetRepaymentRef).get(),
          );
          if (getInvestmentRepaymentsError) {
            throw Error('Error retrieving the investment repayments.');
          }

          // Update investments - investments repayment
          const [investmentRepaymentError] = await to(
            Promise.all(
              getInvestmentRepayments.docs.map(async (investmentRepaymentDoc): Promise<void> => {
                const investmentRepayment = investmentRepaymentDoc.data() as InvestmentRepayment;
                const investmentRepaymentRef = investmentRepaymentDoc.ref;
                const investmentRef = investmentRepayment.investment as firebase.firestore.DocumentReference;

                // Check if the investment financial object is already deleted
                if (investmentRepayment.deleted) {
                  return;
                }

                // Fetch investment
                const [getInvestmentError, getInvestment] = await to(transaction.get(investmentRef));
                if (getInvestmentError || !getInvestment.exists) {
                  throw Error('Error retrieving the investment.');
                }
                const investment = getInvestment.data() as Investment;

                // Update investment repayment
                transactionBatch.addOperation({
                  ref: investmentRepaymentRef,
                  data: {
                    deleted: true,
                    updatedDateTime: timeNow,
                  } as InvestmentRepayment,
                });

                // Update investment
                transactionBatch.addOperation({
                  ref: investmentRef,
                  data: {
                    totalEuroRepayments: new BigNumber(investment.totalEuroRepayments || 0)
                      .minus(investmentRepayment.amount)
                      .toNumber(),
                    totalSharesRepayments: new BigNumber(investment.totalSharesRepayments || 0)
                      .minus(investmentRepayment.shares)
                      .toNumber(),
                    updatedDateTime: timeNow,
                  } as Investment,
                });
              }),
            ),
          );
          if (investmentRepaymentError) {
            throw investmentRepaymentError;
          }

          // COMMIT
          transactionBatch.commit();
        }),
      );
      if (deleteRepaymentError) {
        return commit(SET_REPAYMENTS, {
          status: DataContainerStatus.Error,
          payload: deleteRepaymentError,
          operation: 'deleteRepayment',
        });
      }

      return commit(SET_REPAYMENTS, { status: DataContainerStatus.Success, operation: 'deleteRepayment' });
    },
  },
} as Module<Vertebra, State>;
