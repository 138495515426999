import { GetterTree } from 'vuex';
import { State } from '@/models/State';
import { ManagerRole, ManagerProfile } from '@/models/manager/Manager';
import { CurrentManagerInterface } from '@/models/manager/CurrentManager';
import { Backup } from '@/models/Common';
import { DataChangeRequest } from '@/models/users/User';
import { bloqify, BloqifyConfig } from '@/boot/firebase';
import { Pescheckv3PescheckDocument } from '@/models/identification-requests/pescheck';

export default {
  /**
   * Get current manager, i.e. a Manager instance,
   * hydrated with firebase.User data.
   *
   * @example @Getter getCurrentManager!: CurrentManager;
   * @example this.$store.getters.getCurrentManager
   */
  getCurrentManager: (state): CurrentManagerInterface | null => state.currentManager.payload || null,

  /**
   * Get current manager role.
   *
   * @Getter getCurrentManagerRole!: ManagerRole;
   * @example this.$store.getters.getCurrentManagerRole
   */
  getCurrentManagerRole: (state): ManagerRole =>
    state.currentManager.payload ? state.currentManager.payload.role : ManagerRole.Editor,

  isManager: (state): boolean =>
    state.currentManager.payload ? state.currentManager.payload.role === ManagerRole.Manager : false,

  isCallAgent: (state): boolean =>
    state.currentManager.payload ? state.currentManager.payload.role === ManagerRole.CallAgent : false,

  isSuperAdminOrAdmin: (state): boolean =>
    state.currentManager.payload
      ? [ManagerRole.Superadmin, ManagerRole.Admin].includes(state.currentManager.payload.role)
      : false,

  /**
   * Get current manager profile data.
   *
   * @Getter getManagerProfileData!: ManagerProfile | null;
   * @example this.$store.getters.getManagerProfileData
   */
  getManagerProfileData: (state): ManagerProfile | null => (state.manager ? state.manager.profile || null : null),

  /**
   * Get all backups.
   * @example @Getter getBackups!: any[];
   * @example this.$store.getters.getBackups
   */
  getBackups: (state): { date: string }[] =>
    state.backups.payload
      ? state.backups.payload?.map((backup: Backup): { date: string } => ({
          date: backup.location.path.split('backups/')[1],
        }))
      : [],

  /**
   * Get all pending change requests.
   * @example @Getter getPendingChangeRequests!: any[];
   * @example this.$store.getters.getPendingChangeRequests
   */
  getPendingChangeRequests: (state): DataChangeRequest[] =>
    state.dataChangeRequests.filter(({ status }: DataChangeRequest): boolean => status === 'pending'),

  /** The questionnaire is only executed in rax for now, but will be needed in many other projects.
   * Since there's no whitelabel config in bloqadmin this is how we trigger that functionality for now
   * */
  getQuestionnaireUsed: (): boolean => {
    const { projectId } = bloqify.options as BloqifyConfig;
    return projectId.includes('rax');
  },

  getPescheckByInvestorId:
    (state, getters, rootState): ((id: string) => Pescheckv3PescheckDocument | undefined) =>
    (id: string): Pescheckv3PescheckDocument | undefined =>
      rootState.boundPeschecks?.find((pescheck): boolean => pescheck.id === id),

  // @ts-expect-error - TODO: fix this
  getUserVerified: (state): boolean => state.user?.payload,

  getInvestmentsOverview: (state): string | null => state.investmentsOverview.payload,
} as GetterTree<State, State>;
