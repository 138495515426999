/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  multiclient: {
    enabled: false,
  },
  maxIddleTime: 1800000,
  internalClientName: 'divina',
  name: 'Divina Capital',
  pescheckEnabled: false,
  paymentServiceProvider: 'Custom',
  opp: {
    source: 'manual',
  },
  scssVariables: './src/scss/variables/brands/_divina.scss',
  functionality: {
    bulkImport: true,
    includeOpenPaymentsInAssetExport: false,
    checkoutSessionEnabled: false,
    taxStatementYears: 3,
    languageChoice: {
      enableLanguageChoice: true,
      languages: ['nl', 'en'],
      defaultLanguage: 'nl',
    },
    modules: {
      asset: {
        requiredFields: [
          'name',
          'city',
          'country',
          'startDateTime',
          'endDateTime',
          'euroMin',
          'totalValueEuro',
          'sharePrice',
          'emissionCost',
          'dividendsFormat',
        ],
        fileKeyNames: ['images', 'floorPlanImages', 'prospectus', 'brochure'],
      },
    },
    createAsset: {
      showLocationToggle: false,
      subscriptionDocEnabled: false,
      secondaryDocEnabled: false,
      assetTermsOrLoanAgreementEnabled: false,
      additionalFilesForInvestmentsEnabled: true,
    },
    userDetails: {
      preferredLanguage: false,
    },
    createUser: {
      initialsRequired: false,
      identifierEnabled: true,
      riskClassificationRequired: false,
      kvkRequired: false,
      bankAccountOwnerRequired: false,
    },
    users: {
      pdfDownloadFunctionName: 'generateDividendsPDF',
    },
    investments: {
      fractionalSharesEnabled: true,
      earningsEnabled: false,
      repaymentsEnabled: false,
      loansTabEnabled: true,
    },
    payments: {
      cancellableEnabled: true,
      endingDisabled: false,
      endingDaySelector: false,
      markAsRequestedEnabled: false,
    },
    downloadFundInvestors: {
      requiredFields: {
        customId: { fieldName: 'Klantnummer', origin: 'investor', field: 'customId' },
        projectName: { fieldName: 'Project', origin: 'asset', field: 'name' },
        fundType: { fieldName: 'ProjectType', origin: 'asset', field: 'fundType' },
        kycMethod: { fieldName: 'Type', origin: 'investor', field: 'kycMethod' },
        companyId: { fieldName: 'Bedrijf', origin: 'investor', field: 'companyId' },
        gender: { fieldName: 'Aanhef', origin: 'investor', field: 'gender' },
        initials: { fieldName: 'Voorletter', origin: 'investor', field: 'initials' },
        investorName: { fieldName: 'Voornaam', origin: 'investor', field: 'name' },
        middleName: { fieldName: 'Tussenvoegsel', origin: 'investor', field: 'middleName' },
        surname: { fieldName: 'Achternaam', origin: 'investor', field: 'surname' },
        telephone: { fieldName: 'Telefoon', origin: 'investor', field: 'telephone' },
        experience: { fieldName: 'Ervaren (belegger)', origin: 'investor', field: 'experience' },
        riskClass: { fieldName: 'Risicoclassificatie', origin: 'investor', field: 'riskClass' },
        email: { fieldName: 'Emailadres', origin: 'investor', field: 'email' },
        dateOfBirth: { fieldName: 'Geboortedatum', origin: 'investor', field: 'dateOfBirth' },
        streetAddress: { fieldName: 'Straat', origin: 'investor', field: 'streetAddress' },
        houseNumber: { fieldName: 'Huisnummer', origin: 'investor', field: 'houseNumber' },
        postalCode: { fieldName: 'Postcode', origin: 'investor', field: 'postalCode' },
        city: { fieldName: 'Stad', origin: 'investor', field: 'city' },
        country: { fieldName: 'Land', origin: 'investor', field: 'country' },
        status: { fieldName: 'Status', origin: 'investor', field: 'status' },
        kvk: { fieldName: 'KvK', origin: 'investor', field: 'kvk' },
        passportExpirationDate: {
          fieldName: 'VerloopdatumLegitimatiebewijs',
          origin: 'investor',
          field: 'passportExpirationDate',
        },
        bankAccount: { fieldName: 'IBAN', origin: 'investor', field: 'bankAccount' },
        bankAccountOwner: { fieldName: 'TenNameVan', origin: 'investor', field: 'bankAccountOwner' },
        subscribeNewsLetter: { fieldName: 'Nieuwsbrief', origin: 'investor', field: 'subscribeNewsLetter' },
        receivePhysicalMailings: {
          fieldName: 'CorrespondentiePerPost',
          origin: 'investor',
          field: 'receivePhysicalMailings',
        },
        createdDateTimeInvestment: { fieldName: 'InvesteringStart', origin: 'investment', field: 'createdDateTime' },
        emissionCost: { fieldName: 'Emissiekosten', origin: 'asset', field: 'emissionCost' },
        paidEuroTotal: { fieldName: 'InvesteringsBedrag', origin: 'investment', field: 'paidEuroTotal' },
        boughtSharesTotal: { fieldName: 'AantalDeelnemingen', origin: 'investment', field: 'boughtSharesTotal' },
        totalEuroEarnings: { fieldName: 'UitbetaaldeRente', origin: 'investment', field: 'totalEuroEarnings' },
        totalEuroRepayments: { fieldName: 'Aflossing', origin: 'investment', field: 'totalEuroRepayments' },
        paidEuroTotalAndTotalRepayments: { fieldName: 'InvesteringHeden', origin: 'investment', field: 'custom' },
      },
    },
    investorPortal: {
      availableFundCard: {
        divFormatTypeEnabled: false,
      },
    },
  },
};
