import { Module } from 'vuex';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyStorage, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import BigNumber from 'bignumber.js';
import to from 'await-to-js';
import moment from 'moment';
import { Asset, ExportTableDataType } from '@/models/assets/Asset';
import { AssetEarning, InvestmentEarning } from '@/models/assets/Earnings';
import { Investment } from '@/models/investments/Investment';
import { Vertebra, generateState, mutateState } from '../utils/skeleton';
import { CustomBatch } from '../utils/customBatch';
import { ImportData, generateFileMd5Hask } from '../utils/files';
import BatchInstance from './batches';

export interface AddEarningParam {
  assetId: string;
  date: Date;
  paymentDate: Date;
  amount: number;
  dividendFormat: number;
  earningAmounts: { investmentId: string; amount: number }[];
  description?: string;
}

export interface BulkAddEarningParam {
  assetId: string;
  uploadedFile: File;
  data: ExportTableDataType[];
}

export interface EarningActionParam {
  assetId: string;
  earningId: string;
}

const SET_EARNINGS = 'SET_EARNINGS';

export default {
  state: generateState(),
  mutations: {
    [SET_EARNINGS](
      state,
      { status, payload, operation }: { status: DataContainerStatus; payload?: unknown; operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addEarning(
      { commit },
      { assetId, date, paymentDate, amount, dividendFormat, earningAmounts, description }: AddEarningParam,
    ): Promise<void> {
      commit(SET_EARNINGS, { status: DataContainerStatus.Processing, operation: 'addEarning' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const earningDate = firebase.firestore.Timestamp.fromDate(date);
      const paymentDateTime = firebase.firestore.Timestamp.fromDate(paymentDate);
      const [createEarningError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const timeNow = firebase.firestore.Timestamp.now();
          const transactionBatch = new CustomBatch(transaction);

          // Fetch asset
          const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
          if (getAssetError || !getAssetSuccess.exists) {
            throw Error('Error retrieving the asset.');
          }
          const asset = getAssetSuccess.data() as Asset;

          let dividendsFormat: Asset['dividendsFormat'][0]['contents'] | undefined;
          if (dividendFormat) {
            dividendsFormat = asset.dividendsFormat.find((df): boolean => df.contents[1] === dividendFormat)?.contents;
          }

          // Update asset totals
          transactionBatch.addOperation({
            ref: assetRef,
            data: {
              totalEuroEarnings: new BigNumber(asset.totalEuroEarnings || 0).plus(amount).toNumber(),
              updatedDateTime: timeNow,
            } as Asset,
          });

          // Create asset earning data
          const assetEarningRef = assetRef.collection('assetEarnings').doc();
          const assetEarningData: AssetEarning = {
            asset: assetRef,
            deleted: false,
            totalAmount: amount,
            earningDateTime: earningDate,
            paymentDateTime,
            createdDateTime: timeNow,
            updatedDateTime: timeNow,
            description: description || '',
          };
          transactionBatch.addOperation({
            ref: assetEarningRef,
            data: assetEarningData,
          });

          // For each received investment amount
          const [investmentEarningError] = await to(
            Promise.all(
              earningAmounts.map(async (earningAmount): Promise<void> => {
                const investmentRef = bloqifyFirestore.collection('investments').doc(earningAmount.investmentId);
                const [getInvestmentError, getInvestment] = await to(transaction.get(investmentRef));
                if (getInvestmentError || !getInvestment.exists) {
                  throw Error('Error retrieving the investment.');
                }
                const investment = getInvestment.data() as Investment;

                // Update investment total
                const investmentUpdateData = {
                  totalEuroEarnings: new BigNumber(investment.totalEuroEarnings || 0)
                    .plus(earningAmount.amount)
                    .toNumber(),
                  updatedDateTime: timeNow,
                } as Investment;
                transactionBatch.addOperation({
                  ref: investmentRef,
                  data: investmentUpdateData,
                });

                // Create investment earning data
                const investmentEarningRef = investmentRef.collection('earnings').doc();
                const investmentEarningData: InvestmentEarning = {
                  assetEarning: assetEarningRef,
                  investment: investmentRef,
                  investor: investment.investor,
                  deleted: false,
                  amount: earningAmount.amount,
                  earningDateTime: earningDate,
                  paymentDateTime,
                  createdDateTime: timeNow,
                  updatedDateTime: timeNow,
                  description: description || '',
                  ...(dividendsFormat && { dividendsFormat }),
                };
                transactionBatch.addOperation({
                  ref: investmentEarningRef,
                  data: investmentEarningData,
                });
              }),
            ),
          );
          if (investmentEarningError) {
            throw investmentEarningError;
          }

          // COMMIT
          transactionBatch.commit();
        }),
      );
      if (createEarningError) {
        return commit(SET_EARNINGS, {
          status: DataContainerStatus.Error,
          payload: createEarningError,
          operation: 'addEarning',
        });
      }

      return commit(SET_EARNINGS, { status: DataContainerStatus.Success, operation: 'addEarning' });
    },
    async bulkAddEarning({ commit }, { assetId, uploadedFile, data }: BulkAddEarningParam): Promise<void> {
      commit(SET_EARNINGS, { status: DataContainerStatus.Processing, operation: 'bulkAddEarning' });
      const batch = new BatchInstance(bloqifyFirestore);
      const timeNow = firebase.firestore.Timestamp.now();
      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const assetEarningRef = assetRef.collection('assetEarnings').doc();
      try {
        // Fetch asset data
        const [getAssetError, getAssetSuccess] = await to(assetRef.get());
        if (getAssetError || !getAssetSuccess.exists) {
          throw Error('Error retrieving the asset.');
        }
        const asset = getAssetSuccess.data() as Asset;

        // Prepare doc path
        const fullPath = `assets/${assetRef.id}/${'earnings'}/${moment().format('YYYY_MM_DD_HH_mm_ss')}_${uploadedFile.name}`;

        // Uploading all files including hashes
        const md5Hash = await generateFileMd5Hask(uploadedFile, true);
        bloqifyStorage.ref().child(fullPath).put(uploadedFile, { customMetadata: { md5Hash } });

        // Transform received data, mainly dates to timestamp
        const processedData = data.map((row: ExportTableDataType): Omit<ImportData, 'shares'> => {
          const paymentDateTimestamp = firebase.firestore.Timestamp.fromMillis(
            moment(row.paymentDate, 'DD/MM/YYYY').valueOf(),
          );
          const transactionDateTimestamp = firebase.firestore.Timestamp.fromMillis(
            moment(row.transactionDate, 'DD/MM/YYYY').valueOf(),
          );
          return {
            amount: Number(row.amount),
            customId: Number(row.investorId),
            dividendsFormat: row.dividendType || '',
            description: row.description || '',
            transactionDate: transactionDateTimestamp,
            period: paymentDateTimestamp,
          };
        });

        // Upload docs
        const [processDataError] = await to(
          Promise.all(
            processedData.map(async (row): Promise<void> => {
              // Fetch investor
              const [getInvestorsError, getInvestorsSuccess] = await to(
                bloqifyFirestore.collection('investors').where('customId', '==', row.customId).limit(1).get(),
              );
              if (getInvestorsError || getInvestorsSuccess.empty) {
                throw Error('Error retrieving the investor.');
              }
              const investorRef = getInvestorsSuccess.docs[0].ref;

              // Fetch investment
              const [getInvestmentsError, getInvestmentsSuccess] = await to(
                bloqifyFirestore
                  .collection('investments')
                  .where('deleted', '==', false)
                  .where('investor', '==', investorRef)
                  .where('asset', '==', assetRef)
                  .limit(1)
                  .get(),
              );
              if (getInvestmentsError || getInvestmentsSuccess.empty) {
                throw (
                  getInvestmentsError ||
                  Error(`Error, investor ${row.customId} doesnt have an investment in this asset`)
                );
              }

              const investmentRef = getInvestmentsSuccess.docs[0].ref;
              const investmentData = getInvestmentsSuccess.docs[0].data() as Investment;

              // Update Investment totals
              batch.set(
                investmentRef,
                {
                  totalEuroEarnings: new BigNumber(investmentData.totalEuroEarnings || 0).plus(row.amount).toNumber(),
                  updatedDateTime: timeNow,
                } as Investment,
                { merge: true },
              );

              let dividendsFormat: Asset['dividendsFormat'][0]['contents'] | undefined;
              if (row.dividendsFormat) {
                dividendsFormat = asset.dividendsFormat.find(
                  (df): boolean => df.contents[0].toUpperCase() === row.dividendsFormat.toString().toUpperCase(),
                )?.contents;
              }

              // Set investment earning
              const investmentEarningRef = investmentRef.collection('earnings').doc();
              const investmentEarningData: InvestmentEarning = {
                assetEarning: assetEarningRef,
                investment: investmentRef,
                investor: investorRef,
                deleted: false,
                amount: row.amount,
                earningDateTime: row.transactionDate,
                paymentDateTime: row.period,
                createdDateTime: timeNow,
                updatedDateTime: timeNow,
                description: row.description || '',
                ...(dividendsFormat && { dividendsFormat }),
              };
              batch.set(investmentEarningRef, investmentEarningData);
            }),
          ),
        );
        if (processDataError) {
          throw processDataError;
        }

        // Add all earnings
        const totalAmount = data.reduce((acum, next): BigNumber => acum.plus(next.amount), new BigNumber(0)).toNumber();

        // Set asset earning
        const assetEarningData: AssetEarning = {
          asset: assetRef,
          deleted: false,
          totalAmount,
          earningDateTime: timeNow,
          createdDateTime: timeNow,
          updatedDateTime: timeNow,
          file: fullPath,
          description: '',
        };
        batch.set(assetEarningRef, assetEarningData);

        // Update asset totals
        batch.set(
          assetRef,
          {
            totalEuroEarnings: new BigNumber(asset.totalEuroEarnings || 0).plus(totalAmount).toNumber(),
            updatedDateTime: timeNow,
          } as Asset,
          { merge: true },
        );

        // Commit
        const [commitError] = await to(batch.commit());
        if (commitError) {
          throw commitError;
        }
      } catch (e) {
        console.log(e);
        return commit(SET_EARNINGS, { status: DataContainerStatus.Error, payload: e, operation: 'bulkAddEarning' });
      }
      return commit(SET_EARNINGS, { status: DataContainerStatus.Success, operation: 'bulkAddEarning' });
    },
    async deleteEarning({ commit }, { assetId, earningId }: EarningActionParam): Promise<void> {
      commit(SET_EARNINGS, { status: DataContainerStatus.Processing, operation: 'deleteEarning' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const assetEarningRef = assetRef.collection('assetEarnings').doc(earningId);
      const [deleteEarningError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const timeNow = firebase.firestore.Timestamp.now();
          const transactionBatch = new CustomBatch(transaction);
          // Fetch asset
          const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
          if (getAssetError || !getAssetSuccess.exists) {
            throw Error('Error retrieving the asset.');
          }
          const asset = getAssetSuccess.data() as Asset;

          // Fetch earning
          const [getAssetEarningError, getAssetEarningSuccess] = await to(transaction.get(assetEarningRef));
          if (getAssetEarningError || !getAssetEarningSuccess.exists) {
            throw Error('Error retrieving the earning.');
          }
          const assetEarning = getAssetEarningSuccess.data() as AssetEarning;

          // Check earning not deleted
          if (assetEarning.deleted) {
            throw Error('Earning already erased.');
          }

          // Update asset totals
          transactionBatch.addOperation({
            ref: assetRef,
            data: {
              totalEuroEarnings: new BigNumber(asset.totalEuroEarnings || 0).minus(assetEarning.totalAmount).toNumber(),
              updatedDateTime: timeNow,
            } as Asset,
          });

          // Update asset earning
          transactionBatch.addOperation({
            ref: assetEarningRef,
            data: {
              deleted: true,
              updatedDateTime: timeNow,
            } as AssetEarning,
          });

          // Fetch investments earnings
          const [getInvestmentEarningsError, getInvestmentEarningsSuccess] = await to(
            bloqifyFirestore.collectionGroup('earnings').where('assetEarning', '==', assetEarningRef).get(),
          );
          if (getInvestmentEarningsError) {
            throw Error('Error retrieving the investment earnings.');
          }

          // Update investments - investments earning
          const [investmentEarningError] = await to(
            Promise.all(
              getInvestmentEarningsSuccess.docs.map(async (investmentEarningDoc): Promise<void> => {
                const investmentEarning = investmentEarningDoc.data() as InvestmentEarning;
                const investmentEarningRef = investmentEarningDoc.ref;
                const investmentRef = investmentEarning.investment as firebase.firestore.DocumentReference;

                // Check if the investment financial object is already deleted
                if (investmentEarning.deleted) {
                  return;
                }

                // Fetch investment
                const [getInvestmentError, getInvestment] = await to(transaction.get(investmentRef));
                if (getInvestmentError || !getInvestment.exists) {
                  throw Error('Error retrieving the investment.');
                }
                const investment = getInvestment.data() as Investment;

                // Update investment earning
                transactionBatch.addOperation({
                  ref: investmentEarningRef,
                  data: {
                    deleted: true,
                    updatedDateTime: timeNow,
                  } as InvestmentEarning,
                });

                // Update investment
                transactionBatch.addOperation({
                  ref: investmentRef,
                  data: {
                    totalEuroEarnings: new BigNumber(investment.totalEuroEarnings || 0)
                      .minus(investmentEarning.amount)
                      .toNumber(),
                    updatedDateTime: timeNow,
                  } as Investment,
                });
              }),
            ),
          );
          if (investmentEarningError) {
            throw investmentEarningError;
          }

          // COMMIT
          transactionBatch.commit();
        }),
      );
      if (deleteEarningError) {
        return commit(SET_EARNINGS, {
          status: DataContainerStatus.Error,
          payload: deleteEarningError,
          operation: 'deleteEarning',
        });
      }

      return commit(SET_EARNINGS, { status: DataContainerStatus.Success, operation: 'deleteEarning' });
    },
  },
} as Module<Vertebra, State>;
