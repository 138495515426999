import * as SparkMD5 from 'spark-md5';
import { bloqifyStorage, firebase } from '@/boot/firebase';
import Papa from 'papaparse';

export interface ImportData {
  period: firebase.firestore.Timestamp;
  transactionDate: firebase.firestore.Timestamp;
  amount: number;
  customId: number;
  dividendsFormat: string;
  description: string;
}

/**
 * Function that generates the md5 hash of a file incrementally (by chunks)
 */
export const generateFileMd5Hask = async (file: File, transformToBase64?: boolean): Promise<string> =>
  new Promise((resolve, reject): void => {
    const chunkSize = 2097152; // Read in chunks of 2MB
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    const chunks = Math.ceil(file.size / chunkSize);

    let currentChunk = 0;

    fileReader.onerror = (): void => {
      reject(Error('MD5 computation failed - error reading the file'));
    };

    // read chunk starting at `cursor` into memory
    const loadNext = (): void => {
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize;

      fileReader.readAsArrayBuffer(file.slice(start, end));
    };

    // when it's available in memory, process it
    // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
    // of `any` for `e` variable, otherwise stick with `any`
    // See https://github.com/Microsoft/TypeScript/issues/25510
    fileReader.onload = (e: ProgressEvent<FileReader>): void => {
      if (e.target?.result) {
        spark.append(e.target.result as ArrayBuffer); // Append array buffer
        currentChunk++;

        if (currentChunk < chunks) {
          loadNext();
        } else {
          resolve(transformToBase64 ? btoa(spark.end()) : spark.end());
        }
      }
    };

    loadNext();
  });

/**
 * Function that checks if the file is already in Google Storage
 */
export const checkFileInStorage = async (storagePath: string, generatedMd5Hash: string): Promise<boolean> => {
  const storageRef = bloqifyStorage.ref();
  const fileMetadata = await storageRef.child(storagePath).getMetadata();

  const {
    md5Hash,
    // @ts-expect-error - Missing types
    customMetadata: { md5Hash: customMd5Hash },
  } = fileMetadata;
  if (!md5Hash && !customMd5Hash) {
    throw Error('There was a problem retrieving the hash.');
  }

  return generatedMd5Hash === md5Hash || generatedMd5Hash === customMd5Hash;
};

const getDate = (dateString: string): firebase.firestore.Timestamp => {
  let symbolToUse;
  if (dateString.includes('-')) {
    symbolToUse = '-';
  } else if (dateString.includes('/')) {
    symbolToUse = '/';
  }
  const elements = dateString.split(symbolToUse);
  return firebase.firestore.Timestamp.fromDate(new Date(`${elements[2]}/${elements[1]}/${elements[0]}`));
};

/**
 * transformCsv(file: File)
 * Transform a csv file to a upload data
 */
export const transformCsv = async (file: File): Promise<ImportData[]> =>
  new Promise((resolve, reject): void => {
    Papa.parse(file, {
      error: (e: Error): void => {
        reject(e);
      },
      complete: (results: { data: string[][] }): void => {
        try {
          resolve(
            results.data
              .filter((row): boolean => row && !!row.length && !!row[0])
              .map(
                (row): ImportData => ({
                  period: getDate(row[0]),
                  transactionDate: getDate(row[1]),
                  amount: Number(row[2].replace(',', '.')),
                  customId: Number(row[3]),
                  dividendsFormat: row[4] || '',
                  description: row[5] || '',
                }),
              ),
          );
        } catch (e) {
          reject(e as Error);
        }
      },
    });
  });
