import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { Asset } from '@/models/assets/Asset';
import { Valuation, ValuationStatus } from '@/models/assets/Valuation';
import { DataContainerStatus } from '@/models/Common';
import to from 'await-to-js';
import BigNumber from 'bignumber.js';
import { generateState, mutateState } from '../utils/skeleton';
import { CustomBatch } from '../utils/customBatch';

export interface AddValuationParam {
  assetId: string;
  sharePrice: number;
  totalValueShares: number;
  description: string;
  date: Date;
}

export interface UpdateValuationParam {
  assetId: string;
  valuationId: string;
  sharePrice: number;
  totalValueShares: number;
}

export interface DeleteValuationParam {
  assetId: string;
  valuationId: string;
}

const SET_VALUATION = 'SET_VALUATION';

export default {
  state: generateState(),
  mutations: {
    [SET_VALUATION](
      state,
      { status, payload, operation }: { status: DataContainerStatus; payload?: unknown; operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addValuation(
      { commit },
      { assetId, sharePrice, totalValueShares, description, date }: AddValuationParam,
    ): Promise<void> {
      // Creates a new valuation for the asset
      commit(SET_VALUATION, { status: DataContainerStatus.Processing, operation: 'addValuation' });
      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
          const transactionBatch = new CustomBatch(transaction);
          const applyDateTime = firebase.firestore.Timestamp.fromDate(date);
          const timeNow = firebase.firestore.Timestamp.now();

          // Fetch the asset
          const [getAssetError, getAsset] = await to(transaction.get(assetRef));
          if (getAssetError || !getAsset.exists) {
            throw Error(getAssetError?.message || 'Asset not found');
          }
          const asset = getAsset.data() as Asset;

          // Check if the date is already due
          const futureDate = timeNow < applyDateTime;

          // Create valuation
          const valuationRef = assetRef.collection('valuations').doc();
          const valuationData: Valuation = {
            asset: assetRef,
            clientName: asset.clientName,
            deleted: false,
            sharePrice,
            totalValueShares,
            description,
            status: futureDate ? ValuationStatus.Pending : ValuationStatus.Applied,
            applyDateTime,
            createdDateTime: timeNow,
            updatedDateTime: timeNow,
          };
          transactionBatch.addOperation({
            ref: valuationRef,
            data: valuationData,
          });

          if (!futureDate) {
            // Fetch all past valuations
            const [getAllValuationsError, getAllValuations] = await to(
              assetRef
                .collection('valuations')
                .where('deleted', '==', false)
                .where('status', '==', ValuationStatus.Applied)
                .orderBy('applyDateTime', 'desc')
                .get(),
            );
            if (getAllValuationsError) {
              throw Error(getAllValuationsError.message);
            }

            // Check if the applyDate of the valuation is the most recent one
            const recentDate = applyDateTime >= getAllValuations.docs[0]?.get('applyDateTime').toDate() || new Date(0);

            // If the applyDate is due and its the more recent valuation then we update asset financials
            if (recentDate) {
              // Calculate addiotional financials
              const totalValueEuro = new BigNumber(sharePrice).multipliedBy(totalValueShares).toNumber();
              const sharesAvailable = new BigNumber(totalValueShares)
                .minus(new BigNumber(asset.totalValueShares).minus(asset.sharesAvailable))
                .toNumber();

              // Update Asset
              const assetData = {
                sharesAvailable,
                sharePrice,
                totalValueEuro,
                totalValueShares,
                updatedDateTime: timeNow,
              } as Asset;

              transactionBatch.addOperation({
                ref: assetRef,
                data: assetData,
              });
            }
          }

          // Commit
          transactionBatch.commit();
        }),
      );
      if (transactionError) {
        return commit(SET_VALUATION, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'addValuation',
        });
      }
      return commit(SET_VALUATION, { status: DataContainerStatus.Success, operation: 'addValuation' });
    },
    async updateValuation(
      { commit },
      { assetId, valuationId, sharePrice, totalValueShares }: UpdateValuationParam,
    ): Promise<void> {
      // Creates a new valuation for the asset
      commit(SET_VALUATION, { status: DataContainerStatus.Processing, operation: 'updateValuation' });
      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
          const valuationRef = assetRef.collection('valuations').doc(valuationId);
          const transactionBatch = new CustomBatch(transaction);
          const timeNow = firebase.firestore.Timestamp.now();

          // Fetch the asset
          const [getAssetError, getAsset] = await to(transaction.get(assetRef));
          if (getAssetError || !getAsset.exists) {
            throw Error(getAssetError?.message || 'Asset not found');
          }
          const asset = getAsset.data() as Asset;

          // Fetch the valuation
          const [getValuationError, getValuation] = await to(transaction.get(valuationRef));
          if (getValuationError || !getValuation.exists) {
            throw Error(getValuationError?.message || 'Valuation not found');
          }
          const valuation = getValuation.data() as Valuation;

          // Update valuation data
          transactionBatch.addOperation({
            ref: valuationRef,
            data: {
              sharePrice,
              totalValueShares,
              updatedDateTime: timeNow,
            } as Valuation,
          });

          // If this valuation was already applied we check if
          if (valuation.status === ValuationStatus.Applied) {
            // Fetch all past valuations
            const [getAllValuationsError, getAllValuations] = await to(
              assetRef
                .collection('valuations')
                .where('deleted', '==', false)
                .where('status', '==', ValuationStatus.Applied)
                .orderBy('applyDateTime', 'desc')
                .get(),
            );
            if (getAllValuationsError) {
              throw Error(getAllValuationsError.message);
            }

            // Check if this is the last applied valuation
            const lastValuation = getAllValuations.docs[0]?.id === valuationId;
            // If this is the last applied valuation then the asset financials must be recalculated
            if (lastValuation) {
              // Asset new totals
              const totalValueEuro = new BigNumber(sharePrice).multipliedBy(totalValueShares).toNumber();
              const sharesAvailable = new BigNumber(totalValueShares)
                .minus(new BigNumber(asset.totalValueShares).minus(asset.sharesAvailable))
                .toNumber();

              // Update asset
              transactionBatch.addOperation({
                ref: assetRef,
                data: {
                  sharePrice,
                  sharesAvailable,
                  totalValueShares,
                  totalValueEuro,
                  updatedDateTime: timeNow,
                } as Asset,
              });
            }
          }

          // Commit
          transactionBatch.commit();
        }),
      );
      if (transactionError) {
        return commit(SET_VALUATION, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'updateValuation',
        });
      }
      return commit(SET_VALUATION, { status: DataContainerStatus.Success, operation: 'updateValuation' });
    },
    async deleteValuation({ commit }, { assetId, valuationId }: DeleteValuationParam): Promise<void> {
      // Delete a valuation of an asset
      commit(SET_VALUATION, { status: DataContainerStatus.Processing, operation: 'deleteValuation' });
      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
          const valuationRef = assetRef.collection('valuations').doc(valuationId);
          const transactionBatch = new CustomBatch(transaction);
          const timeNow = firebase.firestore.Timestamp.now();

          if (valuationId === 'initial') {
            throw Error("Can't delete initial valuation of the project");
          }

          // Fetch the asset
          const [getAssetError, getAsset] = await to(transaction.get(assetRef));
          if (getAssetError || !getAsset.exists) {
            throw Error(getAssetError?.message || 'Asset not found');
          }
          const asset = getAsset.data() as Asset;

          // Fetch the valuation
          const [getValuationError, getValuation] = await to(transaction.get(valuationRef));
          if (getValuationError || !getValuation.exists) {
            throw Error(getValuationError?.message || 'Valuation not found');
          }
          const valuation = getValuation.data() as Valuation;

          if (valuation.deleted) {
            throw Error('Valuation already erased');
          }

          transactionBatch.addOperation({
            ref: valuationRef,
            data: {
              deleted: true,
              updatedDateTime: timeNow,
            } as Valuation,
          });

          if (valuation.status === ValuationStatus.Applied) {
            // Fetch all asset past valuations
            const [getAllValuationsError, getAllValuations] = await to(
              assetRef
                .collection('valuations')
                .where('deleted', '==', false)
                .where('status', '==', ValuationStatus.Applied)
                .orderBy('applyDateTime', 'desc')
                .get(),
            );
            if (getAllValuationsError) {
              throw Error(getAllValuationsError.message);
            }

            // Check if this is the last applied valuation
            const lastValuation = getAllValuations.docs[0]?.id === valuationId;

            // If this is the last applied valuation then the asset financials must be overwritted by the previous one
            if (lastValuation) {
              // Fetch previous valuation
              const previousSharePrice = (getAllValuations.docs[1]?.get('sharePrice') as Valuation['sharePrice']) || 0;
              const previousTotalValueShares =
                (getAllValuations.docs[1]?.get('totalValueShares') as Valuation['totalValueShares']) || 0;

              // Recalculate financials
              const previousTotalValueEuro = new BigNumber(previousSharePrice)
                .multipliedBy(previousTotalValueShares)
                .toNumber();
              const previousSharesAvailable = new BigNumber(previousTotalValueShares)
                .minus(new BigNumber(asset.totalValueShares).minus(asset.sharesAvailable))
                .toNumber();

              // Update asset
              transactionBatch.addOperation({
                ref: assetRef,
                data: {
                  sharePrice: previousSharePrice,
                  sharesAvailable: previousSharesAvailable,
                  totalValueShares: previousTotalValueShares,
                  totalValueEuro: previousTotalValueEuro,
                  updatedDateTime: timeNow,
                } as Asset,
              });
            }
          }

          // Commit
          transactionBatch.commit();
        }),
      );
      if (transactionError) {
        return commit(SET_VALUATION, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'deleteValuation',
        });
      }
      return commit(SET_VALUATION, { status: DataContainerStatus.Success, operation: 'deleteValuation' });
    },
  },
};
