import { Module } from 'vuex';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyStorage, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import BigNumber from 'bignumber.js';
import to from 'await-to-js';
import moment from 'moment';
import { Asset, ExportTableDataType } from '@/models/assets/Asset';
import { AssetCost, InvestmentCost } from '@/models/assets/Costs';
import { Investment } from '@/models/investments/Investment';
import { Vertebra, generateState, mutateState } from '../utils/skeleton';
import { CustomBatch } from '../utils/customBatch';
import { ImportData, generateFileMd5Hask } from '../utils/files';
import BatchInstance from './batches';

export interface AddCostParam {
  assetId: string;
  date: Date;
  amount: number;
  costAmounts: { investmentId: string; amount: number }[];
  description?: string;
}

export interface BulkAddCostParam {
  assetId: string;
  uploadedFile: File;
  data: ExportTableDataType[];
}

export interface CostActionParam {
  assetId: string;
  costId: string;
}

const SET_COSTS = 'SET_COSTS';

export default {
  state: generateState(),
  mutations: {
    [SET_COSTS](
      state,
      { status, payload, operation }: { status: DataContainerStatus; payload?: unknown; operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addCost({ commit }, { assetId, date, amount, costAmounts, description }: AddCostParam): Promise<void> {
      commit(SET_COSTS, { status: DataContainerStatus.Processing, operation: 'addCost' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const costDate = firebase.firestore.Timestamp.fromDate(date);
      const [createCostError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const timeNow = firebase.firestore.Timestamp.now();
          const transactionBatch = new CustomBatch(transaction);

          // Fetch asset
          const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
          if (getAssetError || !getAssetSuccess.exists) {
            throw Error('Error retrieving the asset.');
          }
          const asset = getAssetSuccess.data() as Asset;

          // Update asset totals
          transactionBatch.addOperation({
            ref: assetRef,
            data: {
              totalEuroCosts: new BigNumber(asset.totalEuroCosts || 0).plus(amount).toNumber(),
              updatedDateTime: timeNow,
            } as Asset,
          });

          // Create asset cost data
          const assetCostRef = assetRef.collection('assetCosts').doc();
          const assetCostData: AssetCost = {
            asset: assetRef,
            deleted: false,
            totalAmount: amount,
            costDateTime: costDate,
            createdDateTime: timeNow,
            updatedDateTime: timeNow,
            description: description || '',
          };
          transactionBatch.addOperation({
            ref: assetCostRef,
            data: assetCostData,
          });

          // For each received investment amount
          const [investmentCostError] = await to(
            Promise.all(
              costAmounts.map(async (costAmount): Promise<void> => {
                const investmentRef = bloqifyFirestore.collection('investments').doc(costAmount.investmentId);
                const [getInvestmentError, getInvestment] = await to(transaction.get(investmentRef));
                if (getInvestmentError || !getInvestment.exists) {
                  throw Error('Error retrieving the investment.');
                }
                const investment = getInvestment.data() as Investment;

                // Update investment total
                const investmentUpdateData = {
                  totalEuroCosts: new BigNumber(investment.totalEuroCosts || 0).plus(costAmount.amount).toNumber(),
                  updatedDateTime: timeNow,
                } as Investment;
                transactionBatch.addOperation({
                  ref: investmentRef,
                  data: investmentUpdateData,
                });

                // Create investment cost data
                const investmentCostRef = investmentRef.collection('costs').doc();
                const investmentCostData: InvestmentCost = {
                  assetCost: assetCostRef,
                  investment: investmentRef,
                  investor: investment.investor,
                  deleted: false,
                  amount: costAmount.amount,
                  costDateTime: costDate,
                  createdDateTime: timeNow,
                  updatedDateTime: timeNow,
                  description: description || '',
                };
                transactionBatch.addOperation({
                  ref: investmentCostRef,
                  data: investmentCostData,
                });
              }),
            ),
          );
          if (investmentCostError) {
            throw investmentCostError;
          }

          // COMMIT
          transactionBatch.commit();
        }),
      );
      if (createCostError) {
        return commit(SET_COSTS, { status: DataContainerStatus.Error, payload: createCostError, operation: 'addCost' });
      }

      return commit(SET_COSTS, { status: DataContainerStatus.Success, operation: 'addCost' });
    },
    async bulkAddCost({ commit }, { assetId, uploadedFile, data }: BulkAddCostParam): Promise<void> {
      commit(SET_COSTS, { status: DataContainerStatus.Processing, operation: 'bulkAddCost' });
      const batch = new BatchInstance(bloqifyFirestore);
      const timeNow = firebase.firestore.Timestamp.now();
      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const assetCostRef = assetRef.collection('assetCosts').doc();
      try {
        // Fetch asset data
        const [getAssetError, getAssetSuccess] = await to(assetRef.get());
        if (getAssetError || !getAssetSuccess.exists) {
          throw Error('Error retrieving the asset.');
        }
        const asset = getAssetSuccess.data() as Asset;

        // Prepare doc path
        const fullPath = `assets/${assetRef.id}/${'costs'}/${moment().format('YYYY_MM_DD_HH_mm_ss')}_${uploadedFile.name}`;

        // Uploading all files including hashes
        const md5Hash = await generateFileMd5Hask(uploadedFile, true);
        bloqifyStorage.ref().child(fullPath).put(uploadedFile, { customMetadata: { md5Hash } });

        // Transform received data, mainly dates to timestamp
        const processedData = data.map((row: ExportTableDataType): Omit<ImportData, 'shares'> => {
          const paymentDateTimestamp = firebase.firestore.Timestamp.fromMillis(
            moment(row.paymentDate, 'DD/MM/YYYY').valueOf(),
          );
          const transactionDateTimestamp = firebase.firestore.Timestamp.fromMillis(
            moment(row.transactionDate, 'DD/MM/YYYY').valueOf(),
          );
          return {
            amount: Number(row.amount),
            customId: Number(row.investorId),
            dividendsFormat: row.dividendType || '',
            description: row.description || '',
            transactionDate: transactionDateTimestamp,
            period: paymentDateTimestamp,
          };
        });

        // Upload docs
        const [processDataError] = await to(
          Promise.all(
            processedData.map(async (row): Promise<void> => {
              // Fetch investor
              const [getInvestorsError, getInvestorsSuccess] = await to(
                bloqifyFirestore.collection('investors').where('customId', '==', row.customId).limit(1).get(),
              );
              if (getInvestorsError || getInvestorsSuccess.empty) {
                throw Error('Error retrieving the investor.');
              }
              const investorRef = getInvestorsSuccess.docs[0].ref;

              // Fetch investment
              const [getInvestmentsError, getInvestmentsSuccess] = await to(
                bloqifyFirestore
                  .collection('investments')
                  .where('deleted', '==', false)
                  .where('investor', '==', investorRef)
                  .where('asset', '==', assetRef)
                  .limit(1)
                  .get(),
              );
              if (getInvestmentsError || getInvestmentsSuccess.empty) {
                throw (
                  getInvestmentsError ||
                  Error(`Error, investor ${row.customId} doesnt have an investment in this asset`)
                );
              }

              const investmentRef = getInvestmentsSuccess.docs[0].ref;
              const investmentData = getInvestmentsSuccess.docs[0].data() as Investment;

              // Update Investment totals
              batch.set(
                investmentRef,
                {
                  totalEuroCosts: new BigNumber(investmentData.totalEuroCosts || 0).plus(row.amount).toNumber(),
                  updatedDateTime: timeNow,
                } as Investment,
                { merge: true },
              );

              let selectedDividendFormat: Asset['dividendsFormat'][0] | undefined;
              if (row.dividendsFormat) {
                selectedDividendFormat = asset.dividendsFormat.find(
                  (df): boolean => df.contents[0].toUpperCase() === row.dividendsFormat.toString().toUpperCase(),
                );
              }

              // Set investment cost
              const investmentCostRef = investmentRef.collection('costs').doc();
              const investmentCostData: InvestmentCost = {
                assetCost: assetCostRef,
                investment: investmentRef,
                investor: investorRef,
                deleted: false,
                amount: row.amount,
                costDateTime: row.period,
                createdDateTime: row.transactionDate,
                updatedDateTime: timeNow,
                description: row.description || '',
                ...(selectedDividendFormat && { selectedDividendFormat }),
              };
              batch.set(investmentCostRef, investmentCostData);
            }),
          ),
        );
        if (processDataError) {
          throw processDataError;
        }

        // Add all costs
        const totalAmount = data.reduce((acum, next): BigNumber => acum.plus(next.amount), new BigNumber(0)).toNumber();

        // Set asset cost
        const assetCostData: AssetCost = {
          asset: assetRef,
          deleted: false,
          totalAmount,
          costDateTime: timeNow,
          createdDateTime: timeNow,
          updatedDateTime: timeNow,
          file: fullPath,
          description: '',
        };
        batch.set(assetCostRef, assetCostData);

        // Update asset totals
        batch.set(
          assetRef,
          {
            totalEuroCosts: new BigNumber(asset.totalEuroCosts || 0).plus(totalAmount).toNumber(),
            updatedDateTime: timeNow,
          } as Asset,
          { merge: true },
        );

        // Commit
        const [commitError] = await to(batch.commit());
        if (commitError) {
          throw commitError;
        }
      } catch (e) {
        return commit(SET_COSTS, { status: DataContainerStatus.Error, payload: e, operation: 'bulkAddCost' });
      }
      return commit(SET_COSTS, { status: DataContainerStatus.Success, operation: 'bulkAddCost' });
    },
    async deleteCost({ commit }, { assetId, costId }: CostActionParam): Promise<void> {
      commit(SET_COSTS, { status: DataContainerStatus.Processing, operation: 'deleteCost' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const assetCostRef = assetRef.collection('assetCosts').doc(costId);
      const [deleteCostError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const timeNow = firebase.firestore.Timestamp.now();
          const transactionBatch = new CustomBatch(transaction);
          // Fetch asset
          const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
          if (getAssetError || !getAssetSuccess.exists) {
            throw Error('Error retrieving the asset.');
          }
          const asset = getAssetSuccess.data() as Asset;

          // Fetch cost
          const [getAssetCostError, getAssetCostSuccess] = await to(transaction.get(assetCostRef));
          if (getAssetCostError || !getAssetCostSuccess.exists) {
            throw Error('Error retrieving the cost.');
          }
          const assetCost = getAssetCostSuccess.data() as AssetCost;

          // Check cost not deleted
          if (assetCost.deleted) {
            throw Error('Cost already erased.');
          }

          // Update asset totals
          transactionBatch.addOperation({
            ref: assetRef,
            data: {
              totalEuroCosts: new BigNumber(asset.totalEuroCosts || 0).minus(assetCost.totalAmount).toNumber(),
              updatedDateTime: timeNow,
            } as Asset,
          });

          // Update asset cost
          transactionBatch.addOperation({
            ref: assetCostRef,
            data: {
              deleted: true,
              updatedDateTime: timeNow,
            } as AssetCost,
          });

          // Fetch investments costs
          const [getInvestmentCostsError, getInvestmentCostsSuccess] = await to(
            bloqifyFirestore.collectionGroup('costs').where('assetCost', '==', assetCostRef).get(),
          );
          if (getInvestmentCostsError) {
            throw Error('Error retrieving the investment costs.');
          }

          // Update investments - investments cost
          const [investmentCostError] = await to(
            Promise.all(
              getInvestmentCostsSuccess.docs.map(async (investmentCostDoc): Promise<void> => {
                const investmentCost = investmentCostDoc.data() as InvestmentCost;
                const investmentCostRef = investmentCostDoc.ref;
                const investmentRef = investmentCost.investment as firebase.firestore.DocumentReference;

                // Check if the investment financial object is already deleted
                if (investmentCost.deleted) {
                  return;
                }

                // Fetch investment
                const [getInvestmentError, getInvestment] = await to(transaction.get(investmentRef));
                if (getInvestmentError || !getInvestment.exists) {
                  throw Error('Error retrieving the investment.');
                }
                const investment = getInvestment.data() as Investment;

                // Update investment cost
                transactionBatch.addOperation({
                  ref: investmentCostRef,
                  data: {
                    deleted: true,
                    updatedDateTime: timeNow,
                  } as InvestmentCost,
                });

                // Update investment
                transactionBatch.addOperation({
                  ref: investmentRef,
                  data: {
                    totalEuroCosts: new BigNumber(investment.totalEuroCosts || 0)
                      .minus(investmentCost.amount)
                      .toNumber(),
                    updatedDateTime: timeNow,
                  } as Investment,
                });
              }),
            ),
          );
          if (investmentCostError) {
            throw investmentCostError;
          }

          // COMMIT
          transactionBatch.commit();
        }),
      );
      if (deleteCostError) {
        return commit(SET_COSTS, {
          status: DataContainerStatus.Error,
          payload: deleteCostError,
          operation: 'deleteCost',
        });
      }

      return commit(SET_COSTS, { status: DataContainerStatus.Success, operation: 'deleteCost' });
    },
  },
} as Module<Vertebra, State>;
