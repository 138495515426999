<template>
  <div class="refresh-container">
    <p>{{ $tc('common.refreshModal') }}</p>
    <button class="btn bt-light refresh-btn" @click.prevent="onClick">
      {{ $tc('common.refresh') }}
    </button>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class RefreshModal extends Vue {
  onClick(): void {
    this.$emit('click-on-refresh');
  }
}
</script>
<style scoped lang="scss">
@import '~scssVars';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

.refresh-container {
  position: fixed;
  right: 1rem;
  text-align: right;
  z-index: 999;
  bottom: 1rem;
  width: 50%;
  background-color: $primary;
  border-radius: 2rem;
  padding: 1.5rem;
  color: $white;

  p {
    text-align: left;
    font-weight: 400;
    padding-bottom: 0.5rem;
    color: $white;
  }

  @include media-breakpoint-up(md) {
    top: inherit;
    width: 30%;
  }

  @include media-breakpoint-up(xl) {
    top: inherit;
    width: 20%;
  }
}

.refresh-btn {
  width: 100%;
  color: $primary;
  background-color: $white;
  height: 2.6rem;
  border-radius: 2rem;
}
</style>
