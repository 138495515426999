import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { clientConfig } from '@/helpers/clientData';
import deepmerge from 'deepmerge';

Vue.use(VueI18n);

const whitelabelConfig = clientConfig();
const internalClientName = whitelabelConfig?.internalClientName;

const languagesConfig = whitelabelConfig.functionality.languageChoice.languages;
const defaultLanguageConfig = whitelabelConfig.functionality.languageChoice.defaultLanguage;

export type Language = (typeof languagesConfig)[number] | 'nl' | 'en' | 'de';
export const languages: Language[] = languagesConfig || ['nl', 'en'];

export const defaultLanguage: Language = defaultLanguageConfig || 'nl';
export const localStorageKey = 'vue-i18n-language';

export const isValidLang = (lang): lang is Language => languages.includes(lang);

export const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: defaultLanguage,
  fallbackLocale: 'en',
  messages: {}, // set locale messages
});

const isAppleWebkit = navigator.userAgent.includes('AppleWebKit');

/**
 * Paralelly import the default language file and the project language file
 */
const getCombinatedLangFile = async (
  lang: string,
  additionalProperties?: unknown,
): Promise<Record<string, unknown>> => {
  const languageFiles = [
    import(/* webpackChunkName: "lang-default-[request]" */ `@/lang/${lang}.ts`),
    import(/* webpackChunkName: "lang-project-[request]" */ `@/lang/${internalClientName}/${lang}.ts`),
  ];

  const [defaultLanguageFile, projectLanguageFile] = await Promise.all(languageFiles);

  const mergedDefault = {
    ...defaultLanguageFile.default,
    // @ts-expect-error - ToDO: Fix this
    ...(additionalProperties && { ...additionalProperties }),
  };

  return deepmerge(mergedDefault, projectLanguageFile.default);
};

/**
 * Setting up the default language
 */
void (async (): Promise<void> => {
  const combination = await getCombinatedLangFile(defaultLanguage);
  // @ts-expect-error - ToDO: Fix this
  i18n.setLocaleMessage(defaultLanguage, isAppleWebkit ? Object.freeze(combination) : combination);
})();

// Lazy loading of different language
export async function loadLanguageAsync(lang: Language, additionalProperties = undefined): Promise<boolean> {
  if (i18n.locale !== lang || additionalProperties) {
    if (!i18n.messages[lang] || additionalProperties) {
      const combination = await getCombinatedLangFile(lang, additionalProperties);

      if (Object.keys(combination).length > 0) {
        // Only update i18n and localStorage if combination is not empty
        // @ts-expect-error - ToDO: Fix this
        i18n.setLocaleMessage(lang, combination);
      }
    }
    i18n.locale = lang;
  }
  localStorage.setItem(localStorageKey, lang);
  return true;
}
